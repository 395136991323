import React, { useState, useEffect, useRef } from 'react';
import 'assets/Homepage/scss/Landing_page.scss';
import { NavLink, useLocation } from 'react-router-dom';
import Loading from 'components/Homepage/About/Loading';
import HeaderHomepage from 'components/Homepage/Header'
import FooterHomepage from 'components/Homepage/Footer'
import { Helmet } from 'react-helmet';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import {
    updateViewTemplate
} from 'Apis'
import { useApp } from 'components/Homepage/context/AppContext';
const Index = () => {
    const { dataIp, fetchPostIP, productList, setProductList } = useApp();
    const location = useLocation()
    const path = location.pathname
    const pageCategory = path.split("/").pop()
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState([
        {
            id: 'KLD0001',
            img: 'https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/ktech_wocptp.png',
            src1: 'https://ktech-uw1a.onrender.com/',
            src: '/template/KLD0001',
            name: 'mẫu website thương mai điện tử - KLD0001',
            title: 'Ktech commerce',
            view: 0,
            time: '4 - 7',
            price: 'Giá liên hệ',
            type: 'Cá nhân & Tổ chức',
            category: 'website-thuong-mai-dien-tu'
        }
    ]);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-top-1s",
                ".animate-kassdev-right-1s",
                ".animate-kassdev-left-1s",
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-top-2s",
                ".animate-kassdev-right-2s",
                ".animate-kassdev-left-2s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-top-3s",
                ".animate-kassdev-right-3s",
                ".animate-kassdev-left-3s",
                ".animate-kassdev-bottom-3s",
                ".animate-kassdev-top-4s",
                ".animate-kassdev-right-4s",
                ".animate-kassdev-left-4s",
                ".animate-kassdev-bottom-4s",
                ".animate-kassdev-top-5s",
                ".animate-kassdev-right-5s",
                ".animate-kassdev-left-5s",
                ".animate-kassdev-bottom-5s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);

    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);

    useEffect(() => {
        if (dataIp) {
            fetchPostIP({ ...dataIp, urlVisit: '/category/website-thuong-mai-dien-tu' })
                .then(() => {
                    console.log('sent')
                })
        }
        const foundProducts = productList.filter(product => product.category === pageCategory);
        setProduct(foundProducts);
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, [dataIp]);
    const handleViewTemplate = (id) => {
        updateViewTemplate(id)
            .then((result) => {
                // console.log(result)
                const updatedProductList = productList.map((product) =>
                    product.id === id
                        ? { ...product, ...result }
                        : product
                );
                setProductList(updatedProductList);
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <>
            {isLoading ?
                <Loading />
                :
                <><Helmet>
                    <title>Mẫu website E-commerce ( website Thương mại điện tử)</title>
                    <meta name="description" content="Website bán hàng online, website thương mại điện tử (TMĐT) chắc là không còn cần phải giới thiệu dài dòng nữa. Mẫu website bán hàng đẹp – TMĐT của DUC LAN WEBSITE Website cực kì phù hợp với nhu cầu của người Việt: Giao diện đẹp, phù hợp với gu thẫm mỹ của người Việt, sản phẩm hiển thị rõ ràng, hình ảnh kích thước hợp lý, sắc nét, không bị scale ( giãn ) hình." />
                    <meta name="keywords" content="website bán hàng online, website thương mại điện tử, mẫu website bán hàng đẹp, website TMĐT, thiết kế website bán hàng, website thương mại điện tử giá rẻ, website bán hàng chuẩn SEO, website bán hàng responsive, thiết kế website TMĐT, mẫu website thương mại điện tử, website bán hàng Việt Nam, thiết kế website thương mại điện tử, website bán hàng online dễ SEO, website bán hàng trên di động, website bán hàng với tốc độ load nhanh, website thanh toán online, tích hợp thanh toán offline, báo cáo doanh thu website, website bán hàng với khuyến mãi coupon, website bán hàng sản phẩm rõ ràng, website bán hàng tiện lợi cho khách hàng, website bán hàng đa nền tảng" />
                </Helmet>
                    <HeaderHomepage />
                    <div className='category-landing-page'>
                        <div className='row banner-image'>
                            <div className='col-md-7 image animate-kassdev-left-2s'>
                                <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733751653/ecommerce11_nonhjp.png' alt='' />
                            </div>
                            <div className='col-md-5 content'>
                                <div className='text'>
                                    <div className='animate-kassdev-right-1s'>website</div>
                                    <div className='animate-kassdev-right-2s'>e-commerce</div>
                                </div>
                                <div style={{ padding: '20px 0' }} className=' animate-kassdev-right-3s'><a href='tel:0379382992' className='tel' >Tư vấn thiết kế</a></div>
                            </div>
                        </div>
                        <div className='part-product'>
                            <div className='title animate-kassdev-bottom-2s'>Những sản phẩm bạn có thể hứng thú</div>
                            <div className='row list-product'>
                                {
                                    product.map((item, index) => (
                                        <div onClick={() => handleViewTemplate(item.id)} className={index % 2 === 0 ? 'col-md-6 animate-kassdev-left-1s' : 'col-md-6 animate-kassdev-right-1s'}>
                                            <NavLink key={index} to={item.src} className='nav-link'>
                                                <div className="image-container">
                                                    <img
                                                        src={item.img}
                                                        alt={`Image ${index}`}
                                                    />
                                                </div>
                                                <p className='product-name'>{item.name}</p>

                                                <div className='product-price '>{item.price} <span style={{ fontSize: '13px', textTransform: 'uppercase', color: 'white' }}> - {item.view} lượt xem</span></div>
                                                <div className='btn-show'>
                                                    <div className="btn-type">Xem chi tiết</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='row introduce-landing-page'>
                            <div className='col-md-6 text'>
                                <h6 className='title animate-kassdev-left-1s'>Mẫu website E-commerce (Thương mại điện tử)</h6>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Website bán hàng online, website thương mại điện tử (TMĐT) chắc là không còn cần phải giới thiệu dài dòng nữa. Mẫu website bán hàng đẹp – TMĐT của DUC LAN WEBSITE Website cực kì phù hợp với nhu cầu của người Việt:</p>
                                <br></br>
                                <div className='content animate-kassdev-left-1s'>
                                    <ul>
                                        <li className=' animate-kassdev-left-1s'>Giao diện đẹp, phù hợp với gu thẫm mỹ của người Việt, sản phẩm hiển thị rõ ràng, hình ảnh kích thước hợp lý, sắc nét, không bị scale ( giãn ) hình.</li>
                                        <li className=' animate-kassdev-left-1s'>Giá cả, thuộc tính sản phẩm đầy đủ và rõ ràng và tiện cho khách thao tác lựa chọn, so sánh.</li>
                                        <li className=' animate-kassdev-left-1s'>Có thể mở rộng các tính năng khuyến mãi coupon tiện lợi</li>
                                        <li className=' animate-kassdev-left-1s'>Mẫu thiết kế website bán hàng –  thương mại điện tử có sẵn report báo cáo doanh thu thông minh.</li>
                                        <li className=' animate-kassdev-left-1s'>Tích hợp được tất cả các hình thức thanh toán offline, online đầy đủ không thiếu thứ gì.</li>
                                        <li className=' animate-kassdev-left-1s'>Tốc độ load như nhanh chóng, chưa tới 2s/trang.</li>
                                        <li className=' animate-kassdev-left-1s'>Website chuẩn SEO, dễ SEO lên top.</li>
                                        <li className=' animate-kassdev-left-1s'>Giao diện đa nên tảng trên di động thông minh, máy tính tiện lợi cho khách hàng.</li>
                                    </ul>
                                </div>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Với mẫu website bán hàng trực tuyến đẹp bạn sẽ hoàn toàn làm chủ cuộc chơi thương trường của mình. Dễ dàng thực hiện các chiến lược kinh doanh, quảng bá và marketing cho sản phẩm. Với những mẫu bên dưới, DUC LAN WEBSITE hy vọng sẽ giúp bạn tìm được những mẫu ưng ý hoặc có thể nảy ra được ý tưởng cho trang web của bạn. Nếu bạn cần thiết kế website bán hàng theo yêu cầu, hãy liên hệ với DUC LAN WEBSITE theo hotline: 037 938 2992. Chúc bạn thành công!</p>
                            </div>
                            <div className='col-md-6 image-intro animate-kassdev-right-2s'>
                                <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733756969/11111_rcy8u6.png' alt='' />
                            </div>
                        </div>
                        <div className='row type-website'>
                            <div className='title animate-kassdev-bottom-1s'>Danh Mục</div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Landing Page</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Studio - Wedding</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-3s'>
                                        <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Bất động sản</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>

                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Thương Mại Điện Tử Nhỏ</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Dashboard</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Giáo Dục</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Nhà Hàng - Quán Cà Phê</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FooterHomepage />
                </>
            }
        </>
    );
};

export default Index;
