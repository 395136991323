import React, { useState } from 'react';
import 'assets/List_Website/KLD1312/scss/header.scss';
import Logo from 'assets/List_Website/KLD1312/images/logo_education.svg'
import { useNavigate } from 'react-router-dom'
import ArrowDown from 'assets/List_Website/KLD1312/images/arrow_down.svg'
const Index = (props) => {
    const { changePage, setChangePage } = props
    const navigate = useNavigate();
    const [isMenu, setIsMenu] = useState(false)
    const [isDropdownMenu, setIsDropdownMenu] = useState(false)
    const [openSubMenu, setOpenSubMenu] = useState(null);

    const toggleSubMenu = (index) => {
        setOpenSubMenu(openSubMenu === index ? null : index);
    };
    const resetHeaderNav = () => {
        setIsDropdownMenu(false)
        setOpenSubMenu(null)
        setIsMenu(false)
    }
    const handleClick = (url) => {
        setChangePage(true)
        window.location.hash = "#top";
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
            document.body.style.overflow = 'auto';
            navigate(url);
            setChangePage(false)
        }, 900);
    };
    return (
        <>
        {changePage ? <div className="loader1-kld2112"></div> : <div className="loader-kld2112"></div>}
            <div className='header-kld1312'>
                <div className='list-nav'>
                    <div className='logo'>
                        <img src={Logo} alt='Logo' />
                    </div>
                    <div className='nav'>
                        <a onClick={() => handleClick('/website/template/KLD1312')} className='nav-item'>Homepage</a>

                        {/* Dropdown Courses */}
                        <div className="dropdown">
                            <button className="dropdown-button">
                                Courses
                                <span className="dropdown-icon"><img src={ArrowDown} alt='' /></span>
                            </button>
                            <div className="dropdown-menu">
                                <a onClick={() => handleClick('/website/template/KLD1312/course')} className="dropdown-item">
                                    Course 1
                                </a>
                                <div className="dropdown-submenu">
                                    <div className="dropdown-item">
                                        Course 2 <span className="dropdown-icon">▶</span>
                                    </div>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-item">
                                            <a  onClick={() => handleClick('/website/template/KLD1312/course')}>Course 2.1</a>
                                        </div>
                                        <div className="dropdown-item">
                                            <a  onClick={() => handleClick('/website/template/KLD1312/course')}>Course 2.2</a>
                                        </div>
                                    </div>
                                </div>
                                <a  onClick={() => handleClick('/website/template/KLD1312/course')} className="dropdown-item">
                                    Course 3
                                </a>
                            </div>
                        </div>
                        <a onClick={() => handleClick('/website/template/KLD1312/blog')} className='nav-item'>Blog</a>
                        <a onClick={() => handleClick('/website/template/KLD1312/about')} className='nav-item'>About us</a>
                        <a onClick={() => handleClick('/website/template/KLD1312/contact')} className='nav-item'>Contact</a>
                    </div>
                    <a onClick={() => handleClick('/website/template/KLD1312/account')} className='logo'><i className='fa fa-user' style={{ color: 'white', fontSize: 24, cursor: 'pointer' }}></i></a>

                </div>
                <div className='mobile-list-nav'>
                    <img src={Logo} alt='Logo' />
                    <div className='button-list-nav'>
                        <a onClick={() => handleClick('/website/template/KLD1312/account')} className='logo'><i className='fa fa-user' style={{ color: 'white', fontSize: 24, cursor: 'pointer' }}></i></a>
                        <div style={{ display: 'flex', gap: 15, alignItems: 'center', paddingRight: 15 }}>

                            <div id="checkbox2" className={isMenu ? 'checked' : ''}></div>
                            <label class="toggle toggle2" for="checkbox2" onClick={() => { setIsMenu(!isMenu) }}>
                                <div id="bar4" class="bars"></div>
                                <div id="bar5" class="bars"></div>
                                <div id="bar6" class="bars"></div>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`nav-mobile-kld1312 ${isMenu ? 'menu-show' : 'menu-hide'}`}>
                <div className='list-nav'>
                    <a onClick={() => {handleClick('/website/template/KLD1312'); resetHeaderNav()}} className='nav-item' ><i className='fa fa-home'></i> Homepage</a>
                    <div>
                        <div
                            onClick={() => setIsDropdownMenu(!isDropdownMenu)}
                            className='nav-item'
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                <i className='fa fa-book-open'></i>Courses
                            </span>
                            <i className={isDropdownMenu ? 'fa fa-chevron-right active-fa-chevron-right' : 'fa fa-chevron-right deactivate-fa-chevron-right'}></i>
                        </div>
                        <ul className={`sub-menu-kld1312 ${isDropdownMenu ? 'open' : ''}`}>
                        <li><a  onClick={() => {handleClick('/website/template/KLD1312/course'); resetHeaderNav()}}><div>Course 1</div> <span><i className='fa'></i></span></a></li>
                            <li>
                                <button onClick={() => toggleSubMenu(1)}>
                                    <div>Course 2</div> <span><i className={openSubMenu === 1 ? 'fa fa-chevron-right active-fa-chevron-right' : 'fa fa-chevron-right deactivate-fa-chevron-right'}></i></span>
                                </button>
                                
                                    <ul className={`sub-sub-menu-kld1312 ${openSubMenu === 1 ? 'open-sub' : ''}`}>
                                        <li><a  onClick={() => {handleClick('/website/template/KLD1312/course'); resetHeaderNav()}}>
                                            <div>Course 2.1</div> <span></span>
                                        </a></li>
                                        <li><a  onClick={() => {handleClick('/website/template/KLD1312/course'); resetHeaderNav()}}>
                                            <div>Course 2.2</div> <span></span>
                                        </a></li>
                                        <li><a  onClick={() => {handleClick('/website/template/KLD1312/course'); resetHeaderNav()}}>
                                            <div>Course 2.2</div> <span></span>
                                        </a></li>
                                        <li><a  onClick={() => {handleClick('/website/template/KLD1312/course'); resetHeaderNav()}}>
                                            <div>Course 2.3</div> <span></span>
                                        </a></li>
                                    </ul>
                                
                            </li>
                            <li><a  onClick={() => {handleClick('/website/template/KLD1312/course'); resetHeaderNav()}}><div>Course 3</div> <span><i className='fa'></i></span></a></li>
                        </ul>
                    </div>

                    <a  onClick={() => {handleClick('/website/template/KLD1312/blog'); resetHeaderNav()}} className='nav-item' ><i className='fa fa-blog'></i>Blog</a>
                    <a  onClick={() => {handleClick('/website/template/KLD1312/about'); resetHeaderNav()}} className='nav-item' ><i className='fa fa-address-book'></i>About us</a>
                    <a  onClick={() => {handleClick('/website/template/KLD1312/contact'); resetHeaderNav()}} className='nav-item' ><i className='fa fa-message'></i>Contact</a>
                </div>
            </div>

        </>
    );
}

export default Index;
