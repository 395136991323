import React, { useState, useEffect, useRef } from 'react';
import 'assets/Homepage/scss/Landing_page.scss';
import { NavLink, useLocation } from 'react-router-dom';
import Loading from 'components/Homepage/About/Loading';
import HeaderHomepage from 'components/Homepage/Header';
import FooterHomepage from 'components/Homepage/Footer';
import { Helmet } from 'react-helmet';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import {
    updateViewTemplate
} from 'Apis'
import { useApp } from 'components/Homepage/context/AppContext';
const Index = () => {
    const { dataIp, fetchPostIP, productList, setProductList } = useApp();
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const path = location.pathname
    const pageCategory = path.split("/").pop()
    
    const [product, setProduct] = useState([
        {
            id: 'KLD1812',
            img: 'https://res.cloudinary.com/dolydpat4/image/upload/v1735832435/coffee_agfejm.png',
            src1: 'https://kassdev.com/website/template/KLD1812',
            src: '/template/KLD1812',
            name: 'mẫu website coffee - tiệm bánh',
            title: 'Kass Café',
            view: 0,
            time: '4 - 7',
            price: 'Giá liên hệ',
            type: 'Cá nhân & Tổ chức',
            category: 'website-nha-hang-quan-ca-phe'
        }
    ]);

    useEffect(() => {
        if (dataIp) {
            fetchPostIP({...dataIp, urlVisit:'/category/website-nha-hang-quan-ca-phe'})
            .then(() => {
                console.log('sent')
            })
        }
        const foundProducts = productList.filter(product => product.category === pageCategory);
        setProduct(foundProducts);
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, [dataIp]);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-top-1s",
                ".animate-kassdev-right-1s",
                ".animate-kassdev-left-1s",
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-top-2s",
                ".animate-kassdev-right-2s",
                ".animate-kassdev-left-2s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-top-3s",
                ".animate-kassdev-right-3s",
                ".animate-kassdev-left-3s",
                ".animate-kassdev-bottom-3s",
                ".animate-kassdev-top-4s",
                ".animate-kassdev-right-4s",
                ".animate-kassdev-left-4s",
                ".animate-kassdev-bottom-4s",
                ".animate-kassdev-top-5s",
                ".animate-kassdev-right-5s",
                ".animate-kassdev-left-5s",
                ".animate-kassdev-bottom-5s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);

    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    const handleViewTemplate = (id) => {
        updateViewTemplate(id)
            .then((result) => {
                // console.log(result)
                const updatedProductList = productList.map((product) =>
                    product.id === id
                        ? { ...product, ...result }
                        : product
                );
                setProductList(updatedProductList);
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <>
            {isLoading ?
                <Loading />
                :
                <><Helmet>
                <title>Dịch vụ thiết kế website</title>
                <meta name="description" content="Mẫu website nhà hàng – khách sạn – quán cà phê tại DUC LAN WEBSITE là những mẫu website được thiết kế với mục đích: giới thiệu nhà hàng – khách sạn, đặt phòng – đặt tiệc online, giúp khách sạn – nhà hàng xây dựng được hình ảnh thương hiệu của mình và tối ưu trong việc tiếp cận khách hàng tiềm năng. Tất cả mẫu website khách sạn – nhà hàng – quán cà phê tại DUC LAN WEBSITE được thiết kế một cách chuyên nghiệp và chau truốt đến từng chi tiết nhỏ nhất. Thêm vào đó là sự kiểm tra 2 lớp trước khi tung ra bản demo cho khách hàng trải nghiệm." />
                <meta name="keywords" content="mẫu website event, thiết kế website sự kiện, website giới thiệu event, mẫu website quảng bá sự kiện, website tổ chức sự kiện, landing page sự kiện, website đăng ký sự kiện, website mua vé sự kiện, thiết kế website sự kiện online, website sự kiện offline, website tổ chức sự kiện chuyên nghiệp, website sự kiện đẹp, website sự kiện hiệu quả, mẫu website landing page sự kiện, website event chuẩn SEO, website sự kiện responsive, website event cho doanh nghiệp, website event cho công ty, thiết kế website event độc quyền"/>
            </Helmet>
                    <HeaderHomepage />
                    <div className='category-landing-page'>
                        <div className='row banner-image'>
                            <div className='col-md-7 image animate-kassdev-left-2s'>
                            <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733751652/landingpage_xu4ais.png' alt='' />
                            </div>
                            <div className='col-md-5 content' style={{ alignItems: 'center' }} >
                                <h6 className='text'>
                                    <div className='animate-kassdev-right-1s' style={{ textAlign: 'center' }}>Website Nhà hàng - Quán Cà Phê</div>
                                    <div className='animate-kassdev-right-1s' style={{ textAlign: 'end' }}></div>
                                </h6>
                                <div style={{ padding: '20px 0' }} className=' animate-kassdev-right-3s'><a href='tel:0379382992' className='tel' >Tư vấn thiết kế</a></div>
                            </div>
                        </div>
                        <div className='part-product'>
                            <div className='title animate-kassdev-left-2s'>Những sản phẩm bạn có thể <span style={{ color: '#50adff' }}>hứng thú</span></div>
                            <div className='row list-product'>
                                {
                                    product.map((item, index) => (
                                        <div onClick={() => handleViewTemplate(item.id)} className={index % 2 === 0 ? 'col-md-6 animate-kassdev-left-1s' : 'col-md-6 animate-kassdev-right-1s'}>
                                            <NavLink key={index} to={item.src} className='nav-link'>
                                                <div className="image-container">
                                                    <img
                                                        src={item.img}
                                                        alt={`Image ${index}`}
                                                    />
                                                </div>
                                                <p className='product-name'>{item.name}</p>

                                                <div className='product-price '>{item.price} <span style={{ fontSize: '13px', textTransform: 'uppercase', color: 'white' }}> - {item.view} lượt xem</span></div>
                                                <div className='btn-show'>
                                                    <div className="btn-type">Xem chi tiết</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='row introduce-landing-page'>
                            <div className='col-md-6 text'>
                                <h6 className='title animate-kassdev-left-1s'>Mẫu website nhà hàng - quán cà phê</h6>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'><span style={{ color: '#50adff' }}>Mẫu website nhà hàng – khách sạn – quán cà phê</span> tại <span style={{ color: '#50adff' }}>DUC LAN WEBSITE</span> là những mẫu website được thiết kế với mục đích: giới thiệu nhà hàng – khách sạn, đặt phòng – đặt tiệc online, giúp khách sạn – nhà hàng xây dựng được hình ảnh thương hiệu của mình và tối ưu trong việc tiếp cận khách hàng tiềm năng.</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Tất cả <span style={{ color: '#50adff' }}>mẫu website khách sạn – nhà hàng – quán cà phê</span> tại <span style={{ color: '#50adff' }}>DUC LAN WEBSITE</span> được thiết kế một cách chuyên nghiệp và chau truốt đến từng chi tiết nhỏ nhất. Thêm vào đó là sự kiểm tra 2 lớp trước khi tung ra bản demo cho khách hàng trải nghiệm.</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Những tính năng vô cùng quan trọng và đặc biệt của <span style={{ color: '#50adff' }}>mẫu website nhà hàng – villa </span>mà ít nơi nào có được bao gồm:</p>
                                <br></br>
                                <div className='content'>
                                    <ul>
                                        <li className=' animate-kassdev-left-1s'>Giao diện của <span style={{ color: '#50adff' }}>thiết kế website khách sạn – quán cà phê</span> được thiết kế chuẩn SEO – gia tăng hiệu quả và khả năng tiếp cận khách hàng mục tiêu</li>
                                        <li className=' animate-kassdev-left-1s'>Đa nền tảng – <span style={{ color: '#50adff' }}>thiết kế chuẩn responsive</span>: gia tăng trải nghiệm người dùng với khả năng truy cập trên mọi thiết bị di động, Ipad…</li>
                                        <li className=' animate-kassdev-left-1s'><span style={{ color: '#50adff' }}>Chuẩn UI/UX</span> – là khả năng mang lại trải nghiệm tốt nhất cho người dùng từ giao diện hình ảnh đến tính năng, hiệu ứng của website. Đặc biệt là tốc độ tải trang chỉ vỏn vẹn trong 2 giây – không khách hàng nào phải chờ đợi</li>
                                        <li className=' animate-kassdev-left-1s'>Tính năng đặt phòng – đặt tiệc Online vô cùng dễ dàng cho người sử dụng cũng như người quản lý website. Việc đặt phòng – đặt tiệc sẽ nhanh chóng và thuận tiện cả khi sử dụng điện thoại hoặc máy tính</li>
                                    </ul>
                                </div>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Mời bạn tham khảo những mẫu <span style={{ color: '#50adff' }}>website khách sạn – nhà hàng – quán cà phê</span> đẹp nhất và chuyên nghiệp nhất của <span style={{ color: '#50adff' }}>DUC LAN WEBSITE</span> bên dưới để có được sự lựa chọn hoặc ý tưởng độc đáo cho <span style={{ color: '#50adff' }}>thiết kế website nhà hàng</span> của mình! Chúc bạn thành công!</p>
                            </div>
                            <div className='col-md-6 image-intro animate-kassdev-right-2s'>
                            <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733756969/11111_rcy8u6.png' alt='' />
                            </div>
                        </div>
                        <div className='row type-website'>
                            <div className='title animate-kassdev-bottom-1s'>Danh Mục</div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Landing Page</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Studio - Wedding</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-3s'>
                                        <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Bất động sản</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>

                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Thương Mại Điện Tử Nhỏ</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Dashboard</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Giáo Dục</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Nhà Hàng - Quán Cà Phê</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FooterHomepage />
                </>
            }
        </>
    );
};

export default Index;
