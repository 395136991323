import React, { useEffect, useState } from 'react';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import 'assets/List_Website/KLD2812/scss/portfolio.scss'
import HeaderKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Header'
import FooterKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Footer'
const Index = () => {
    const [changePage, setChangePage] = useState(false)
    useEffect(() => {
        const sections = document.querySelectorAll(".animate-section");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate");
                    }
                });
            },
            { threshold: 0.3 }
        );

        sections.forEach((section) => observer.observe(section));
        return () => observer.disconnect();
    }, []);
    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    return (
        <>
            <HeaderKLD2812 setChangePage={setChangePage} changePage={changePage} />
            <div className="portfolio-kld2812"style={{ opacity: changePage ? 0 : 1, transition: 'all 0.4s' }}>
                <div className="section-1-kld2812">
                    <img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6465e4909bb93a5288353040_Danilo-and-Sharon-Modern-Luxury-Wedding-Photography-Studio.avif"></img>
                    <div className='introduce'>
                        <div className='title animate-section'>Danh mục</div>
                        <div className="text animate-section">Tuyển tập những tác phẩm đẹp nhất của chúng tôi trong mười năm qua</div>
                        <div className="text animate-section">Nguồn cảm hứng cho các cô dâu tương lai</div>
                    </div>
                </div>
                <div className="section-2-kld2812">
                    <div className="title animate-section">Khám phá những thứ tốt nhất của chúng tôi trong thập kỷ qua và khám phá cách chúng tôi nói lên trái tim của bạn</div>
                </div>
                <div className="section-8-kld2812">
                    <div className="col-3 col-3-1">
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        
                    </div>
                    <div className="col-3  col-3-2" >
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                    </div>
                    <div className="col-3  col-3-3">
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                        
                    </div>
                    <div className="col-3">
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                    </div>
                </div>
                <div className="section-11-kld2812">
                    <div className="title animate-section">Dear John & Diana</div>
                    <div className="box-sent">
                        <div className=' animate-section text'>Tên tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Họ & Tên' />
                        <div className="text animate-section">và tôi đang lên kế hoạch</div>
                        <input type="text" className=' animate-section input-text' placeholder='Lễ cưới' />
                        <div className="text animate-section">trong</div>
                        <input type="text" className=' animate-section input-text' placeholder='Trung tâm' />
                        <div className="text animate-section">vào</div>
                        <input type="text" className=' animate-section input-text' placeholder='Ngày x tháng x năm xxxx' />
                        <div className="text animate-section">người lập kế hoạch đám cưới của tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Nguyễn Văn A' />
                        <div className="text animate-section">và tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='@Nguyễn Thị B' />
                        <div className="text animate-section">trên instagram</div>
                    </div>
                    <div className="btn-sent animate-section">Vui lòng chờ...</div>
                </div>
            </div>
            <FooterKLD2812 />
        </>
    );
};

export default Index;
