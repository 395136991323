import React, { useEffect, useState } from 'react';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import 'assets/List_Website/KLD2812/scss/contact.scss'
import HeaderKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Header'
import FooterKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Footer'
const Index = () => {
    const [changePage, setChangePage] = useState(false)
    useEffect(() => {
        const lenis = new Lenis();

        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);

        return () => lenis.destroy();
    }, []);
    return (
        <>
            <style>
                {`
            body{
                background-color: rgb(243 243 243);
            }
            `}
            </style>
            <HeaderKLD2812 setChangePage={setChangePage} changePage={changePage} />
            <div className='contact-kld2812'style={{ opacity: changePage ? 0 : 1, transition: 'all 0.4s' }}>
                <div className="section-11-kld2812">
                    <div className="title animate-section">Dear John & Diana</div>
                    <div className="box-sent">
                        <div className=' animate-section text'>Tên tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Họ & Tên' />
                        <div className="text animate-section">và tôi đang lên kế hoạch</div>
                        <input type="text" className=' animate-section input-text' placeholder='Lễ cưới' />
                        <div className="text animate-section">trong</div>
                        <input type="text" className=' animate-section input-text' placeholder='Trung tâm' />
                        <div className="text animate-section">vào</div>
                        <input type="text" className=' animate-section input-text' placeholder='Ngày x tháng x năm xxxx' />
                        <div className="text animate-section">người lập kế hoạch đám cưới của tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Nguyễn Văn A' />
                        <div className="text animate-section">và tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='@Nguyễn Thị B' />
                        <div className="text animate-section">trên instagram</div>
                    </div>
                    <div className="btn-sent animate-section">Vui lòng chờ...</div>
                </div>
            </div>
            <FooterKLD2812 />
        </>
    );
}

export default Index;
