import React, { useState, useEffect } from 'react';
import 'assets/Homepage/scss/process.scss';
import { NavLink } from 'react-router-dom';
import Loading from 'components/Homepage/About/Loading';
import HeaderHomepage from 'components/Homepage/Header';
import FooterHomepage from 'components/Homepage/Footer';
import { Helmet } from 'react-helmet';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import { useApp } from 'components/Homepage/context/AppContext';
const Index = () => {
    const { dataIp, fetchPostIP } = useApp();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (dataIp) {
            fetchPostIP({ ...dataIp, urlVisit: '/service/qua-trinh-thuc-hien' })
                .then(() => {
                    console.log('sent')
                })
        }
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, [dataIp]);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-top-1s",
                ".animate-kassdev-right-1s",
                ".animate-kassdev-left-1s",
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-top-2s",
                ".animate-kassdev-right-2s",
                ".animate-kassdev-left-2s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-top-3s",
                ".animate-kassdev-right-3s",
                ".animate-kassdev-left-3s",
                ".animate-kassdev-bottom-3s",
                ".animate-kassdev-top-4s",
                ".animate-kassdev-right-4s",
                ".animate-kassdev-left-4s",
                ".animate-kassdev-bottom-4s",
                ".animate-kassdev-top-5s",
                ".animate-kassdev-right-5s",
                ".animate-kassdev-left-5s",
                ".animate-kassdev-bottom-5s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);

    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);

    return (
        <>
            {isLoading ?
                <Loading />
                :
                <><Helmet>
                    {/* <title>Mẫu Website Landing Page</title>
                    <meta name="description" content="Mẫu website landing page là một loại website đang rất được ưa chuộng hiện nay vì hiệu quả mà nó mang lại. Giao diện landing page sẽ phù hợp nhất cho những website giới thiệu công ty tổng quan và chuyên sâu về công ty hoặc một sản phẩm nào đó nhằm PR, quảng cáo sản phẩm, công ty đến với khách hàng một cách nhanh và thân thiện nhất. Bản chất của Landing page sẽ thể hiện tất cả thông tin về công ty hoặc sản phẩm lên trên cùng một trang web, khách hàng có thể theo dõi tất cả thông tin mà không cần chuyển đi bất cứ một trang nào khác." />
                    <meta name="keywords" content="mẫu website Landing Page, Landing Page đẹp, thiết kế Landing Page, mẫu Landing Page chuẩn SEO, landing page công ty, landing page sản phẩm, landing page bất động sản, landing page mỹ phẩm, landing page xe hơi, landing page GYM, thiết kế website landing page, tạo landing page, thiết kế website chuẩn SEO, website một trang, tối ưu landing page, landing page chuẩn responsive, dịch vụ thiết kế landing page, xây dựng landing page theo yêu cầu, dịch vụ SEO chuyên nghiệp, landing page tại DUC LAN, thiết kế website tại DUC LAN, dịch vụ website DUC LAN" /> */}
                </Helmet>
                    <HeaderHomepage />
                    <div className='sell-website-process'>

                        <div className='title-process-step'>
                            <div className="title animate-kassdev-right-1s">Quá trình thực hiện</div>
                            <div className='sub-title animate-kassdev-right-2s'>
                                Quá trình thực hiện này thông qua
                                <span style={{ color: '#50adff' }}> 6 bước chính </span>
                                nhằm mục đích đảm bảo khách hàng có thể <span style={{ color: '#50adff' }}>hợp tác</span> và <span style={{ color: '#50adff' }}>minh bạch </span>
                                cũng như <span style={{ color: '#50adff' }}>lắng nghe phản hồi</span> của khách hàng để <span style={{ color: '#50adff' }}>sản phẩm hoàn thiện</span> đúng với yêu cầu đã đưa ra .
                            </div>
                        </div>
                        <div className='sell-website-process-work'>

                            <div className='col-md-2 step-process animate-kassdev-top-1s'>
                                <div className="title">01</div>
                                <div className="sub-title">Xác nhận yêu cầu</div>
                                <div className="text">Xác nhận và thu thâp thông tin về thiết kế website</div>
                                <div className="box-content">
                                    <hr />
                                    <div className="sub-title">Thu thập thông tin</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Tiếp nhận yều cầu thiết kế từ khác hàng</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Thu thập thông tin cần thiết về mục tiêu, đối tượng</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Thu thập các yêu cầu cụ thể của khách hàng về giao diện và chức năng của website</div>
                                    <div className="sub-title">Đánh giá ban đầu</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Xác định rõ ràng mục đích của website và nhu cầu của khách hàng, bao gồm mục tiêu kinh doanh, đối tượng người dùng mục tiêu và các tính năng cần thiết</div>
                                </div>
                            </div>
                            <div className='col-md-2 step-process animate-kassdev-top-2s'>
                                <div className="title">02</div>
                                <div className="sub-title">Tư vấn & trao đổi</div>
                                <div className="text">Trao đổi với khách hàng về nhu cầu chức năng & giao diện, báo giá</div>
                                <div className="box-content">
                                    <hr />
                                    <div className="sub-title">Phân tích yêu cầu</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Trao đổi chi tiết với khách hàng để hiểu rõ hơn về yêu cầu thiết kế và chức năng</div>
                                    <div className="sub-title">Tư vấn và đề xuất</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Tư vấn cho khách hàng về giao diện và chức năng phù hợp, đồng thời báo giá chi tiết và đề xuất thời gian thực hiện dự án</div>
                                </div>
                            </div>
                            <div className='col-md-2 step-process animate-kassdev-top-3s'>
                                <div className="title">03</div>
                                <div className="sub-title">Ký hợp đồng</div>
                                <div className="text">Để tạo niềm tin cho cả khách hàng và người thực hiện</div>
                                <div className="box-content">
                                    <hr />
                                    <div className="sub-title">Ký hợp đồng</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Kí hợp đồng bao gồm các điều khoản về phạm vi công việc, chi phí, thời gian hoàn thành và các điều khoản thanh toán</div>
                                </div>
                            </div>
                            <div className='col-md-2 step-process animate-kassdev-top-4s'>
                                <div className="title">04</div>
                                <div className="sub-title">Thiết kế & code</div>
                                <div className="text">Xây dựng và viết code theo như trao đổi trước đó</div>
                                <div className="box-content">
                                    <hr />
                                    <div className="sub-title">Thiết kế giao diện</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Tạo các mô hình giao diện chi tiết (mockup) dựa trên các wireframe đã thảo luận trước đó, bao gồm màu sắc, phông chữ và hình ảnh thương hiệu</div>
                                    <div className="sub-title">Phát triển website</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Chuyển đổi các thiết kế thành các trang website chức năng sử dụng bằng ReactJs về phía giao diện và NodeJs về phía cơ sở dữ liệu vv.vv</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Sử dụng các thư viện dành riêng cho website, và dữ liệu phù hợp. Thiết kế hiệu ứng dành cho website</div>
                                </div>
                            </div>
                            <div className='col-md-2 step-process animate-kassdev-top-5s'>
                                <div className="title">05</div>
                                <div className="sub-title">Test ( kiểm thử )</div>
                                <div className="text">Khách hàng sẽ xem và phản hồi để chỉnh sửa theo yêu cầu</div>
                                <div className="box-content">
                                    <hr />
                                    <div className="sub-title">Test các chức năng</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Đảm bảo tất cả các chức năng hoạt động 1 cách mượt mà và ổn định không xảy ra lỗi</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Kiểm tra kỹ lưỡng các yếu tố như liên kết, biểu mẫu, khả năng tương thích các thiết bị</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Kiểm tra trải nghiệm giao diện người dùng UI / UX</div>
                                    <div className="sub-title">Phát triển website</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Deploy ( triển khai ) website lên môi trường hoạt động trên internet, cấu hình hosting và tên miền</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Đảm bảo các biện pháp bảo mật cần thiết</div>
                                    <div className="sub-title">Phát triển website</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Theo dõi website để phát hiện và sửa lỗi phát sinh ( Gần như không phát sinh lỗi )</div>
                                </div>
                            </div>
                            <div className='col-md-2 step-process animate-kassdev-top-5s'>
                                <div className="title">06</div>
                                <div className="sub-title">Hoàn tất</div>
                                <div className="text">Hoàn thành và bàn giao code cũng như website sản phẩm</div>
                                <div className="box-content">
                                    <hr />
                                    <div className="sub-title">Bàn giao sản phẩm</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Bàn giao source code của website</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Bàn giao website đã đưa lên môi trường internet</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Bàn giao quản trị hosting, tên miền, vv.vv</div>
                                    <div className="sub-title">Hướng dẫn</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Hướng dẫn khách hàng cách sử dụng và quản lý website</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Hướng dẫn khách hàng chạy website ( nếu khách hàng muốn tự điều chỉnh )</div>
                                    <div className="sub-title">Hỗ trợ</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Hỗ trợ chỉnh sửa khi có lỗi phát sinh với website</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Bảo hành website trọn đời</div>
                                    <div className="text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none"><path d="M7.29784 12.9999C7.24944 12.9999 7.20157 12.9906 7.15723 12.9727C7.11289 12.9548 7.07304 12.9287 7.04019 12.8959L0.093221 5.96473C0.0469107 5.91852 0.0162099 5.86086 0.00487599 5.79881C-0.00645791 5.73676 0.00206669 5.673 0.0294066 5.61535C0.0567465 5.5577 0.101716 5.50865 0.15881 5.4742C0.215905 5.43976 0.282649 5.42141 0.350872 5.42141H3.69476C3.74497 5.42142 3.79459 5.43135 3.84028 5.45055C3.88596 5.46975 3.92665 5.49777 3.95961 5.53271L6.28131 7.99636C6.53222 7.50165 7.01795 6.67793 7.87031 5.6742C9.13039 4.19033 11.4742 2.00802 15.4842 0.0379785C15.5617 -9.01509e-05 15.6519 -0.00997048 15.7369 0.0102886C15.8219 0.0305477 15.8956 0.0794735 15.9434 0.147405C15.9912 0.215337 16.0097 0.297336 15.9952 0.377211C15.9806 0.457086 15.9341 0.529031 15.8649 0.578839C15.8496 0.589875 14.3035 1.71289 12.5241 3.76989C10.8865 5.66284 8.70954 8.7581 7.63834 12.754C7.61952 12.8242 7.57575 12.8866 7.51402 12.9312C7.45229 12.9758 7.37614 13 7.29774 13L7.29784 12.9999Z" fill="#50adff"></path></svg>&emsp;Hỗ trợ tư vấn khách hàng liên tục 24/7 về kỹ thuật khi cần</div>
                                </div>
                            </div>
                        </div>
                        <div className='title-process-step'>
                            <div className="title animate-kassdev-right-1s">Các câu hỏi thường gặp</div>
                            <div className='sub-title animate-kassdev-right-2s'>
                                Dưới đây là 1 số <span style={{ color: '#50adff' }}>câu hỏi</span> phổ biến mà người dùng <span style={{ color: '#50adff' }}>thắc mắc</span> khi <span style={{ color: '#50adff' }}>thiết kế website</span>, hãy cùng nhau giải đáp nha!!
                            </div>
                        </div>
                        <div className="accordion-box">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item animate-kassdev-bottom-1s">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            1. Cần cung cấp gì để bắt đầu tiến hành xây dựng website?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <div className="box-text">
                                                <div className="text">Để có thể tiến hành xây dựng website thì khách hàng cần chuận bị những thứ sau đây:</div>
                                            </div>
                                            <div className="box-text">
                                                <div className="title">Thông tin về doanh nghiệp hoặc ngành nghề liên quan:</div>
                                                <ul>
                                                    <li>Tên công ty, logo, và khẩu hiệu slogan ( nếu có sẵn thì tuyệt vời )</li>
                                                    <li>Mô tả về công ty, ngành nghề kinh doanh, và các giá trị cốt lõi.</li>
                                                    <li>Địa chỉ, số điện thoại, email và các thông tin khác tuỳ vào mô hình website</li>
                                                </ul>
                                            </div>
                                            <div className="box-text">
                                                <div className="title">Nội dung tại website:</div>
                                                <ul>
                                                    <li>Nội dung về sản phẩm / dịch vụ, các bài viết về giới thiệu công ty, đội ngũ nhân viên, thông tin liên hệ, và các nội dung khác</li>
                                                    <li>Hình ảnh, video, tài liệu marketing ( nếu có sẵn thì tuyệt vời )</li>
                                                </ul>
                                            </div>
                                            <div className="box-text">
                                                <div className="text">"Trước mắt đó là những thứ đầu tiên mà khách hàng nên chuẩn bị để thiết kế 1 website theo mong muốn"</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item animate-kassdev-bottom-1s">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            2. Hợp đồng thiết kế website, có hay không?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <div className="box-text">
                                                <div className="text">Như trong quá trình thực hiện ở trên, sau khi qua bước thứ 2 và "Tư vấn & trao đổi". Giữa khách hàng và người thực hiện thiết kế website sẽ ký kết hợp đồng</div>
                                            </div>
                                            <div className="box-text">
                                                <div className="text">Nhằm đảm bảo tính minh bạch và quyền lợi dành cho cả 2 phía, sẽ luôn ký kết hợp đồng khi bắt đàu dự án website. Hợp đồng sẽ ghi rõ các đièu khoản, yêu cầu công việc, thời gian hoàn thành và chi phí</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item animate-kassdev-bottom-1s">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            3. Quá trình thiết kế website chi tiết
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <div className="box-text">
                                                <div className="text">Với nhu cầu và đáp ứng những mong muốn khắt khe về chất lượng và mang đến cho khách hàng những trải nghiệp và dịch vụ tốt nhất. DUC LAN WEBSITE hiện đang áp dụng quá trình thực hiện nhằm đảm bảo rằng website được thiết kế và xây dựng sẽ khiến cho khách hàng phải thốt lên "WOW"</div>
                                            </div>
                                            <div className="box-text">
                                                <div className="text">Bạn có thể xem chi tiết <NavLink to='/service/qua-trinh-thuc-hien' style={{ color: '#50adff' }}>&nbsp;quy trình thiết kế website tại dây&nbsp;</NavLink></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item animate-kassdev-bottom-1s">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            4. Các loại chi phí cần đầu tư khi thiết kế website
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <div className="box-text">
                                                <div className="text">Chi phí mua tên miền ( Domain ): Tên miền là địa chỉ của website trên Intermet. Chi phí này thường được thanh toán hàng năm:</div>
                                            </div>
                                            <div className="box-text">
                                                <div className="text">Có rất nhiều tên miền khác nhau phổ biến như .com .vn .com.vn .net với nhiều loại tên miền khác nhau với giá từ 300.000 VNĐ - 1.000.000 VNĐ</div>
                                            </div>
                                            <div className="box-text">
                                                <div className="text">Chi phí Hosting / VPS: Hosting / VPS là nơi lưu trữ dữ liệu và giúp website hoạt động trực tuyến. Chi phí thường được tính theo năm.</div>
                                            </div>
                                            <div className="box-text">
                                                <ul>
                                                    <li>Từ 200.000 VNĐ - 5.000.000 VNĐ (hosting có giá trị càng cao thì khả năng đáo ứng lưu lượng truy cập càng lớn)</li>
                                                </ul>
                                            </div>
                                            <div className="box-text">
                                                <div className="text">Chi phí phát triển xây dựng website: Đây là chi phí chính để tạo ra giao diện và chức năng của website theo yêu cầu của khách hàng</div>
                                            </div>
                                            <div className="box-text">
                                                <ul>
                                                    <li>Theo mẫu dự sẵn giá từ 5.000.000 VNĐ - 20.000.000 VNĐ</li>
                                                    <li>Thiết kế độc quyềngiá từ 20.000.000 VNĐ trở lên</li>
                                                </ul>
                                            </div>
                                            <div className="box-text">
                                                <div className="text">"Chi phí trên đây là tham khảo để có con số cuối cùng hãy <a href="tel:0379382992" style={{ color: '#50adff' }}>liên hệ với tôi</a> để có mức giá phù hợp cho bạn"</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item animate-kassdev-bottom-1s">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                            5. Dịch vụ thiết kế website của tôi có gì nổi bật?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <div className="box-text">
                                                <div className="text">"Chắc hẳn bạn đã xem qua mẫu giao diện tại website của tôi, hoặc nếu bạn chưa xem qua thì có thể ghé  <NavLink to='https://kassdev.com' style={{ color: '#50adff' }}>qua xem thử tại đây</NavLink>"</div>
                                            </div>
                                            <div className="box-text">
                                                <div className="text">Tôi biết rằng website nó cũng giống như 1 gương mặt đại diện của các bạn ở trên Internet. Cho nên tôi tập trung 1000% công lực vào thông tin và hiệu ứng chuyển động và giao diện website của bạn trở nên hiện đại nhất với những dòng code hiện đại nhất bây giờ.</div>
                                            </div>
                                            <div className="box-text">
                                                <div className="text">
                                                    Chưa hết ở đó, tôi còn hỗ trợ dài hạn và nâng cấp website của bạn bất cứ lúc nào. Website hiện đại có gì, chỉ cần bạn muốn là sẽ có được
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row type-website'>
                            <div className='title animate-kassdev-bottom-1s'>Danh Mục</div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Landing Page</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Studio - Wedding</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-3s'>
                                        <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Bất động sản</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>

                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Thương Mại Điện Tử Nhỏ</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Dashboard</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Giáo Dục</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Nhà Hàng - Quán Cà Phê</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FooterHomepage />
                </>
            }
        </>
    );
};

export default Index;
