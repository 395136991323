import React, { useState } from 'react';
import 'assets/List_Website/KLD1812/scss/header.scss'
import Logo from 'assets/List_Website/KLD1812/images/logo-restaurant.svg'
import { useNavigate } from 'react-router-dom';
const Index = (props) => {
    const { changePage, setChangePage } = props
    const navigate = useNavigate();
    const [isMenu, setIsMenu] = useState(false)
    const handleClick = (url) => {
        setChangePage(true)
        window.location.hash = "#top";
        setTimeout(() => {
            navigate(url);
            setChangePage(false)
        }, 900);
    };
    return (
        <>
            {changePage ? <div className="loader1-kld2112"></div> : <div className="loader-kld2112"></div>}
            <div className='header-kld1812'>
                <div className='desktop-kld1812'>
                    <div className='nav-bar-left'>
                        <a onClick={() => handleClick('/website/template/KLD1812/cafe-kass')}>cafe kass</a>
                        <a onClick={() => handleClick('/website/template/KLD1812/do-chay')}>Đồ chay</a>
                        <a onClick={() => handleClick('/website/template/KLD1812/ngoi-nha-banh')}>Ngôi nhà bánh</a>
                    </div>
                    <a to={'/website/template/KLD1812'}><img src={Logo} alt="" /></a>
                    <div className='nav-bar-right'>
                        <a onClick={() => handleClick('/website/template/KLD1812/ky-nghi')}>kỳ nghỉ</a>
                        <a onClick={() => handleClick('/website/template/KLD1812/tiec-rieng-tu')}>Tiệc riêng tư</a>
                        <a onClick={() => handleClick('/website/template/KLD1812/ve-chung-toi')}>Về chúng tôi</a>
                    </div>
                </div>
                <div className='mobile-kld1812'>
                    <img src={Logo} alt="" />
                    <div id="checkbox2" className={isMenu ? 'checked' : ''}></div>
                    <label class="toggle toggle2" for="checkbox2" onClick={() => { setIsMenu(!isMenu) }}>
                        <div id="bar4" class="bars"></div>
                        <div id="bar5" class="bars"></div>
                        <div id="bar6" class="bars"></div>
                    </label>
                </div>
                <div className={`nav-mobile-kld1812 ${isMenu ? 'menu-show-kld1812' : 'menu-hide-kld1812'}`}>
                    <div className='list-nav'>
                        <a onClick={() => { handleClick('/website/template/KLD1812/cafe-kass'); setIsMenu(!isMenu) }} className='nav-item'>Cafe kass</a>
                        <a onClick={() => { handleClick('/website/template/KLD1812/do-chay'); setIsMenu(!isMenu) }} className='nav-item'>Đồ chay</a>
                        <a onClick={() => { handleClick('/website/template/KLD1812/ngoi-nha-banh'); setIsMenu(!isMenu) }} className='nav-item'>Ngồi nhà bánh</a>
                        <a onClick={() => { handleClick('/website/template/KLD1812/ky-nghi'); setIsMenu(!isMenu) }} className='nav-item'>kỳ nghỉ</a>
                        <a onClick={() => { handleClick('/website/template/KLD1812/tiec-rieng-tu'); setIsMenu(!isMenu) }} className='nav-item'>tiệc riêng tư</a>
                        <a onClick={() => { handleClick('/website/template/KLD1812/ve-chung-toi'); setIsMenu(!isMenu) }} className='nav-item'>về chúng tôi</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
