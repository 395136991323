import React, { useState, useEffect } from 'react';
import HeaderHomepage from 'components/Homepage/Header'
import FooterHomepage from 'components/Homepage/Footer'
import Loading from 'components/Homepage/About/Loading';
import 'assets/Homepage/scss/Blog.scss'
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import { useApp } from 'components/Homepage/context/AppContext'
const Index = () => {
    const { dataIp, fetchPostIP } = useApp();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (dataIp) {
            fetchPostIP({ ...dataIp, urlVisit: '/blog' })
                .then(() => {
                    console.log('sent')
                })
        }
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, [dataIp]);
    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-bottom-3s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);
    return (
        <>
            {isLoading ?
                <Loading />
                :
                <><Helmet>
                    <title>Dịch vụ thiết kế website</title>
                    <meta name="description" content="Tôi cung cấp dịch vụ thiết kế website với rất nhiều giá trị. Tôi tự tin rằng sẽ giúp các bạn mang lại nhiều đơn hàng bằng các giải pháp để có website dành cho cá nhân hoặc doanh nghiệp. Cam kết hỗ trợ trọn đời. Thiết kế website theo yêu cầu chỉ từ 1.000.000 VND / Page. Thuê website chỉ từ 10.000đ / 1 ngày & 250.000đ / 1 tháng" />
                    <meta name="keywords" content="bảo trì website, bảo trì website là gì, bảo trì website cần thiết, các bước bảo trì website, thủ tục bảo trì website, dịch vụ bảo trì website, bảo trì website định kỳ, bảo trì website chuyên nghiệp, sửa chữa website, bảo trì website lâu dài" />
                    <meta name="keywords" content="thiết kế website theo yêu cầu, thiết kế website độc quyền, thiết kế website chuyên nghiệp, dịch vụ thiết kế website, thiết kế website DUC LAN, thiết kế website chất lượng, website độc quyền, thiết kế web tại DUC LAN WEBSITE" />
                    <meta name="keywords" content="domain là gì, dịch vụ domain, đăng ký domain, mua domain, dịch vụ domain DUC LAN, tên miền website, dịch vụ tên miền, domain giá rẻ, tên miền chất lượng, DUC LAN Website domain" />
                </Helmet>
                    <HeaderHomepage isLoading={isLoading} />
                    <div className='blog-website'>
                        <div className='list-blog-website'>
                            <div className='title animate-kassdev-bottom-1s'>
                                Blog
                            </div>
                            <div className='row list-blog'>
                                <div className='col-md-4 content'>
                                    <NavLink to={'/service/bao-tri-sua-chua-website'}>
                                        <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733664102/CA988A04-C8B4-435B-BC6F-27CF06E11F97_1_201_a_nqrlqx.jpg' alt='' />
                                        <div className='time animate-kassdev-bottom-1s' >March 31, 2022</div>
                                        <h6 className='name animate-kassdev-bottom-2s'>Bảo trì website là gì? Có cần thiết không? Thủ tục các bước bảo trì web</h6>
                                    </NavLink>
                                </div>
                                <div className='col-md-4 content'>
                                    <NavLink to={'/service//thiet-ke-website-rieng'}>
                                        <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733655842/banner_hjzwnv.png' alt='' />
                                        <div className='time animate-kassdev-bottom-1s' >March 31, 2022</div>
                                        <h6 className='name animate-kassdev-bottom-2s'>Thiết kế website theo yêu cầu – độc quyền – chuyên nghiệp tại DUC LAN WEBSITE</h6>
                                    </NavLink>
                                </div>
                                <div className='col-md-4 content'>
                                    <NavLink to={'/service/ten-mien-website-domain'}>
                                        <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733658826/domain-la-gi-3_aizbuu.png' alt='' />
                                        <div className='time animate-kassdev-bottom-1s' >March 31, 2022</div>
                                        <h6 className='name animate-kassdev-bottom-2s'>Domain là gì? Dịch vụ domain của DUC LAN Website</h6>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='row type-website'>
                                <div className='title animate-kassdev-bottom-1s'>Danh Mục</div>
                                <div className='col-md-4 content'>
                                    <ul>
                                        <li className='animate-kassdev-bottom-1s'>
                                            <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>Landing Page</p>
                                            </NavLink>
                                        </li>
                                        <li className='animate-kassdev-bottom-2s'>
                                            <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>Studio - Wedding</p>
                                            </NavLink>
                                        </li>
                                        <li className='animate-kassdev-bottom-3s'>
                                            <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>Website Bất động sản</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-4 content'>
                                    <ul>

                                        <li className='animate-kassdev-bottom-1s'>
                                            <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>Website Thương Mại Điện Tử Nhỏ</p>
                                            </NavLink>
                                        </li>
                                        <li className='animate-kassdev-bottom-2s'>
                                            <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>Website Dashboard</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-4 content'>
                                    <ul>
                                        <li className='animate-kassdev-bottom-1s'>
                                            <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>Website Giáo Dục</p>
                                            </NavLink>
                                        </li>
                                        <li className='animate-kassdev-bottom-2s'>
                                            <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>Website Nhà Hàng - Quán Cà Phê</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterHomepage />
                </>
            }
        </>
    );
}

export default Index;
