import React, { useEffect, useState, useRef } from 'react';
import 'assets/List_Website/KLD2112/scss/first_st.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import HeaderKLD2112 from 'components/List_Website/Website_property/KLD2112/Header'
import FooterKLD2112 from 'components/List_Website/Website_property/KLD2112/Footer'
import CountUp from 'react-countup';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
const Index = () => {
    const [changePage, setChangePage] = useState(false)
    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    const NextArrow = ({ onClick }) => {
        return (
            <div
                className='btn-prev'
                onClick={onClick}
            >
                <i className='fa fa-chevron-right' style={{ color: '#af8647' }}></i>
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div
                onClick={onClick}
                className='btn-next'
            >
                <i className='fa fa-chevron-left' style={{ color: '#af8647' }}></i>
            </div>
        );
    };
    const NextArrowTime = ({ onClick }) => {
        return (
            <div
                className='btn-next'
                onClick={onClick}
            >
                <i className='fa fa-chevron-right' style={{ color: '#af8647' }}></i>
            </div>
        );
    };

    const PrevArrowTime = ({ onClick }) => {
        return (
            <div
                onClick={onClick}
                className='btn-prev'
            >
                <i className='fa fa-chevron-left' style={{ color: '#af8647' }}></i>
            </div>
        );
    };
    const [currentSlide, setCurrentSlide] = useState(1);
    const settings = {
        infinite: true,
        slidesToShow: 1,
        className: "center",
        centerMode: true,
        centerPadding: "250px",
        speed: 500,
        dots: true,
        nextArrow: <NextArrow />, // Truyền mũi tên tới
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 950, // Dưới 768px (màn hình di động)
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "80px",
                },
            },
        ],
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex + 1); // Cập nhật số trang hiện tại
        },
        appendDots: () => (
            <div style={{ textAlign: "center", padding: "10px" }}>
                <div>{currentSlide}</div>
                <div
                    style={{
                        height: "2px",
                        background: "#d3d3d3", // Màu xám
                        position: "relative",
                        width: "100%",
                        borderRadius: "1px",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            background: "#af8647", // Màu xanh dương
                            width: `${(currentSlide / 21) * 100}%`,
                            transition: "width 0.5s ease",
                        }}
                    ></div>
                </div>
                <div>21</div>
            </div>
        )
    };
    const settings2 = {
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        cssEase: "ease-in-out",
        infinite: true,
        lazyLoad: "ondemand",
        adaptiveHeight: true,
        pauseOnHover: false,
        dots: false,
        nextArrow: <NextArrowTime />,
        prevArrow: <PrevArrowTime />,
        responsive: [
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            },
        ],
    };
    useEffect(() => {
        const sections = document.querySelectorAll(".animate-section");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate");
                    }
                });
            },
            {
                threshold: 0.2,
            }
        );

        sections.forEach((section) => observer.observe(section));
        return () => {
            sections.forEach((section) => observer.unobserve(section));
        };

    }, []);
    const [isShowMore, setIsShowMore] = useState(false)
    const [isTIme, setIsTime] = useState(false)
    const [form, setForm] = useState({
        date: '',
        time: '',
        method: 'online',
        name: 'Phòng ngủ',
        phoneNumber: '',
        email: ''
    })
    const [isForm, setIsForm] = useState(false)
    const handleBlur = (dropdown) => {
        if (dropdown === 'time') setIsTime(false);
    };
    const handleChange = (name, value) => {
        setForm(form => ({ ...form, [name]: value }));
    };

    const getNextFiveDays = () => {
        const today = new Date();
        return Array.from({ length: 7 }, (_, i) => {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            return {
                dayOfWeek: date.toLocaleDateString('vi-VN', { weekday: 'long' }), // Thứ hai, Thứ ba, ...
                day: date.getDate(), // 1, 2, 3...
                month: date.toLocaleDateString('vi-VN', { month: 'long' }), // Tháng mười hai
                year: date.getFullYear()
            };
        });
    };


    const [price, setPrice] = useState("3,775,000");
    const [downPayment, setDownPayment] = useState("755,000");
    const [downPaymentRate, setDownPaymentRate] = useState(20)
    const [interestRate, setInterestRate] = useState(7);
    const [loanTerm, setLoanTerm] = useState("10");
    const [taxes, setTaxes] = useState('4,729')
    const [feeFlower, setFeeFlower] = useState('3,477')
    const [monthlyPayment, setMonthlyPayment] = useState(null);
    const [isPaymentYear, setIsPaymentYear] = useState(false)
    const handleBlurPaymentYear = (dropdown) => {
        if (dropdown === 'tra-gop') setIsPaymentYear(false);
    };
    const calculateMortgagePayment = (price, downPayment, interestRate, loanTerm) => {
        const monthlyInterestRate = interestRate / 100 / 12;
        const loanAmount = price - downPayment;
        const totalMonths = loanTerm * 12;
        const payment =
            (loanAmount * monthlyInterestRate) /
            (1 - Math.pow(1 + monthlyInterestRate, -totalMonths));

        return payment.toFixed(2);
    };
    const formatNumber = (num) => {
        if (!num) return "";
        return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const unformatNumber = (formattedValue) => {
        return formattedValue.replace(/,/g, "");
    };
    const handleDownPaymentChange = (e) => {
        let value = unformatNumber(e.target.value);
        if (value === '') {
            setDownPayment('0');
            setDownPaymentRate(0);
            return;
        }
        if (parseFloat(value) > parseFloat(unformatNumber(price))) {
            const newDownPayment = unformatNumber(price) * (20 / 100);
            const formattedValue = formatNumber(String(newDownPayment));
            setDownPayment(formattedValue);
            setDownPaymentRate(20);
            return;
        }
        if (value.startsWith('0') && value.length > 1) {
            value = value.replace(/^0+/, '');
        }
        const formattedValue = formatNumber(String(value));
        setDownPayment(formattedValue);
        setDownPaymentRate((parseFloat(value) / parseFloat(unformatNumber(price)) * 100).toFixed(2));
    };
    const handleTaxesChange = (e) => {
        let value = unformatNumber(e.target.value);
        if (value === '') {
            setTaxes('0');
            return;
        }
        if (value.startsWith('0') && value.length > 1) {
            value = value.replace(/^0+/, '');
        }
        const formattedValue = formatNumber(String(value));
        setTaxes(formattedValue);
    };
    const handleFeeFlowerChange = (e) => {
        let value = unformatNumber(e.target.value);
        if (value === '') {
            setFeeFlower('0');
            return;
        }
        if (value.startsWith('0') && value.length > 1) {
            value = value.replace(/^0+/, '');
        }
        const formattedValue = formatNumber(String(value));
        setFeeFlower(formattedValue);
    };

    const handleRateChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            setInterestRate(0)
            return;
        }
        if (value <= 100) {
            setInterestRate(parseInt(value, 10))
        }
    };
    const handleDownPaymentRateChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            setDownPayment('0')
            setDownPaymentRate(0)
            return;
        }
        if (value <= 100) {
            const newDownPayment = unformatNumber(price) * (value / 100)
            setDownPayment(formatNumber(String(newDownPayment)))
            setDownPaymentRate(parseInt(value, 10))
        }
    };
    useEffect(() => {
        const result = calculateMortgagePayment(
            parseFloat(unformatNumber(price)),
            parseFloat(unformatNumber(downPayment)),
            parseInt(interestRate, 10),
            parseInt(loanTerm, 10)
        );
        setMonthlyPayment(result);
    }, [price, downPayment, interestRate, loanTerm]);

    return (
        <>
            <style>
                {`
          body {
            background-color: black !important;
          }
        `}
            </style>
            <HeaderKLD2112 setChangePage={setChangePage} changePage={changePage} />
            <div className='first-st-kld2112' style={{ opacity: changePage ? 0 : 1, transition: 'all 0.4s' }}>

                <div className="section-1-kld2112">
                    <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192861/6297720076254051579_sha1lu.jpg"></img>
                    <div className='introduce'>
                        <div className='title animate-section'>360 East 88th Street Unit: 15CD</div>
                        <div className="sub-title animate-section">New York City, NY  - $3,775,000</div>
                    </div>
                    <div className='btn-intro animate-section'>
                        <div className="btn-ele">Scroll <br /> <i className='fa fa-arrow-down'></i></div>
                    </div>
                </div>
                <div className="section-2-kld2112 animate-section">
                    Thông tin
                </div>
                <div className="section-3-kld2112 animate-section">
                    Trải nghiệm Trifecta hoàn hảo tại 360 E 88 #15CD: Giá chỉ 3.775.000 đô la - Giá trị tốt nhất tại Upper East Side!
                </div>
                <div className="section-4-kld2112">
                    <div className="text animate-section">Trải nghiệm Perfect Trifecta tại 360 E 88 #15CD: Chỉ còn $3.775.000 - Giá trị tốt nhất tại Upper East Side!</div>
                    <div className="text animate-section">Khám phá căn hộ chung cư 4 phòng ngủ, 3,5 phòng tắm được cải tạo tuyệt đẹp này tại Upper East Side, hiện đang được bán với mức giá giảm đáng kể. Đây là cơ hội duy nhất để sở hữu một nơi ở sang trọng tại một trong những vị trí đáng mơ ước nhất của Manhattan.</div>
                    <div className={isShowMore ? 'show-less-more active-show-less-more' : 'show-less-more'}>
                        <div className="text animate-section">Tại sao nên chọn 360 E 88 #15CD?</div>
                        <div className="text animate-section">Giá mới không thể đánh bại cho mỗi foot vuông: Giảm từ $3.895.000 xuống $3.775.000, cung cấp 2.900 foot vuông không gian sống sang trọng với giá trị tốt nhất trong phân khúc.</div>
                        <div className="text animate-section">Phí hàng tháng thấp nhất dưới $9.000: Tận hưởng sự sang trọng mà không phải trả chi phí bảo trì cao.</div>
                        <div className="text animate-section">Thời điểm hoàn hảo với lãi suất thế chấp thấp hơn: Tận dụng lãi suất thế chấp thấp hiện tại, với khả năng giảm thêm sau đợt cắt giảm lãi suất sắp tới của Fed. Sự thanh lịch và thoải mái được định nghĩa lại</div>
                        <div className="text animate-section">Căn hộ chung cư rộng 2.900 ft vuông này là nơi nghỉ dưỡng đô thị, nơi mọi chi tiết đều toát lên sự tinh tế và thoải mái. Được thiết kế khéo léo bởi kiến ​​trúc sư nổi tiếng David Howell, toàn bộ căn hộ có sàn gỗ cứng tuyệt đẹp, trần nhà cao 9 feet và cửa sổ lớn tràn ngập không gian với ánh sáng tự nhiên từ ba hướng, tạo nên bầu không khí tươi sáng và thoáng mát. Không gian lưu trữ đặc biệt bao gồm ba tủ quần áo đi bộ quá khổ và phòng giặt rộng rãi.</div>
                        <div className="text animate-section">Không gian công cộng mở rộng rãi bao gồm khu vực sinh hoạt chính thức, khu vực ăn uống và không gian truyền thông, lý tưởng cho cả giải trí và sinh hoạt hàng ngày. Hệ thống chiếu sáng tích hợp và hệ thống AV đảm bảo bầu không khí liền mạch và hấp dẫn.</div>
                        <div className="text animate-section">Bếp của đầu bếp. Bếp của đầu bếp này thực sự là một kiệt tác ẩm thực, được thiết kế cho cả đầu bếp bình thường và đầu bếp chuyên nghiệp. Nó có các tính năng:</div>
                        <div className="text animate-section">Tủ bếp Ý Boffi cho vẻ ngoài bóng bẩy và hiện đại</div>
                        <div className="text animate-section">Hai máy rửa chén Miele giúp vệ sinh dễ dàng</div>
                        <div className="text animate-section">Hai lò nướng âm tường đáp ứng mọi nhu cầu nấu nướng của bạn</div>
                        <div className="text animate-section">Bếp nấu sáu lò có máy hút mùi thông gió để kiểm soát nhiệt độ chính xác</div>
                        <div className="text animate-section">Tủ lạnh Sub-Zero giữ cho nguyên liệu của bạn luôn tươi ngon</div>
                        <div className="text animate-section">Tủ làm mát rượu vang để có những lựa chọn ướp lạnh hoàn hảo</div>
                        <div className="text animate-section">Phòng đựng thức ăn lớn cung cấp không gian lưu trữ rộng rãi</div>
                        <div className="text animate-section">Rèm che điện có thể tách biệt nhà bếp với không gian chung, tạo ra một khung cảnh riêng tư hoặc chỉ đơn giản là mang lại sự riêng tư khi cần</div>
                        <div className="text animate-section">Phòng giặt có không gian lưu trữ rộng rãi với Máy giặt và máy sấy Electrolux cỡ lớn, bồn rửa giặt và tủ lạnh side-by-side bổ sung, hoàn hảo để lưu trữ thêm.</div>
                        <div className="text animate-section">Nơi nghỉ ngơi riêng tư, sang trọng</div>
                        <div className="text animate-section">Phòng ngủ chính mang đến một nơi nghỉ ngơi yên tĩnh với phòng tắm riêng rộng rãi, có cửa sổ với bồn rửa đôi và sàn có hệ thống sưởi ấm. Mỗi phòng ngủ đều mang đến một nơi nghỉ ngơi thanh bình với không gian tủ quần áo rộng rãi và lớp hoàn thiện sang trọng, đảm bảo sự thoải mái và riêng tư.</div>
                        <div className="text animate-section">Tiện nghi độc quyền và vị trí đắc địa</div>
                        <div className="text animate-section">Leighton House cung cấp dịch vụ sang trọng trọn gói với nhân viên gác cửa 24 giờ, lễ tân và người quản lý cư trú tại chỗ. Các tiện nghi bao gồm:</div>
                        <div className="text animate-section">- Khu vườn và sân chơi có sân bóng rổ</div>
                        <div className="text animate-section">- Phòng tập thể dục được trang bị đầy đủ với phòng tập yoga và phòng xông hơi khô</div>
                        <div className="text animate-section">- Thuận tiện đi đến Tàu điện ngầm 2nd Avenue</div>
                        <div className="text animate-section">- Các lựa chọn mua sắm gần đó như Whole Foods và Fairway</div>
                        <div className="text animate-section">- Gần Công viên Carl Schurz để có trải nghiệm ngoài trời yên tĩnh</div>
                        <div className="text animate-section">Bây giờ là lúc hành động - Ngôi nhà mơ ước của bạn đang chờ đón. Hãy tận dụng mức giá mới thấp hơn và đảm bảo căn hộ chung cư cao cấp này với lãi suất thế chấp ưu đãi, với khả năng giảm thêm sau đợt cắt giảm lãi suất sắp tới của Fed. Đây là giá trị tốt nhất ở Upper East Side. Đừng bỏ lỡ chuyến tham quan bất động sản độc quyền bằng video để xem điều gì khiến 360 E 88 #15CD trở thành cơ hội vô song tại Manhattan.</div>
                    </div>
                    {isShowMore ?
                        <div className="btn-show-less-more animate-section" onClick={() => { setIsShowMore(!isShowMore); document.querySelector('.section-3-kld2112').scrollIntoView({ behavior: 'smooth', block: 'start' }); }}>
                            <div>Hiển thị ít hơn</div>
                            <i className="fa fa-arrow-up"></i>
                        </div>
                        :
                        <div className="btn-show-less-more animate-section" onClick={() => setIsShowMore(!isShowMore)}>
                            <div>Hiển thị thêm</div>
                            <i className="fa fa-arrow-right"></i>
                        </div>
                    }
                </div>
                <div className="row section-5-kld2112">
                    <div className="col-md-4">
                        <div className="title animate-section">1989</div>
                        <div className="text animate-section">Năm xây dựng</div>
                    </div>
                    <div className="col-md-4">
                        <div className="title animate-section">4</div>
                        <div className="text animate-section">Tổng số phong ngủ</div>
                    </div>
                    <div className="col-md-4">
                        <div className="title animate-section">4</div>
                        <div className="text animate-section">Tổng số phong tắm</div>
                    </div>
                    <div className="col-md-4">
                        <div className="title animate-section">0,25 <span>mẫu</span></div>
                        <div className="text animate-section">Kích thước lô đất</div>
                    </div>
                    <div className="col-md-4">
                        <div className="title animate-section">2.900 <span>feet</span></div>
                        <div className="text animate-section">Không gian sống</div>
                    </div>

                </div>
                <div className="section-7-kld2112 slider-container animate-section">
                    <Slider {...settings}>
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192875/-1457898965983807999_yqnhc7.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192862/5234940031396559327_bu1bte.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192861/7666993169783427189_ltappu.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192861/-4476959738893360564_icud5s.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192861/6297720076254051579_sha1lu.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192860/3184121237591901446_dsf0zl.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192859/-1373628531046824687_inn4ks.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192859/-5839934520041059178_tcwqmi.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192859/-6883239833866701517_vtoime.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192859/-785615791491446686_iixpyf.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192859/-6446417152574060156_p5opsk.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192858/-7730561270264868346_fghzwj.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192858/8157034756104506897_wbs4gr.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192858/5262458396123423255_xd8wmi.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192858/-5936138005935439655_xnpiop.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192779/20904857250884385_vshuj5.jpg" alt="" />
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192777/-2525301891453134020_ehfkmu.jpg" alt="" />
                    </Slider>
                </div>
                <div className="section-8-kld2112">
                    {/* <div className="col-md-6 left">
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192859/-5839934520041059178_tcwqmi.jpg" alt="" />
                    </div>
                    <div className="col-md-6 right">
                        
                    </div> */}
                    <div className='section-8-1-image-kld2112 animate-section'>
                        <div className='section-8-1-color-kld2112'></div>
                        <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735192859/-5839934520041059178_tcwqmi.jpg"></img>
                    </div>
                    <div className='introduce animate-section'>
                        <div className='introduce-1'>
                            {isForm && <div className="content-right">
                                <div className="title">Lên lịch một chuyến tham quan</div>
                                <div className="text">Chúng tôi rất muốn giới thiệu cho bạn bất động sản tuyệt đẹp của chúng tôi. Vui lòng chọn ngày và giờ bạn muốn bên dưới. Một đại lý sẽ sớm liên hệ để xác nhận cuộc hẹn của bạn.</div>
                                <input type="text" placeholder='Họ và tên đầy đủ' />
                                <input type="text" placeholder='Điện thoại' />
                                <input type="text" placeholder='Địa chỉ Email' />
                                <div className="button-group">
                                    <div className="button-div">
                                        <button className='custom-button' style={{ borderRadius: 15 }}>Lên lịch 1 chuyến tham quan <i className='fa fa-arrow-right'></i></button>
                                    </div>
                                </div>
                                <div className="text">{form.date} lúc {form.time}</div>
                                <div className="text" onClick={() => setIsForm(!isForm)} style={{ borderBottom: '1px solid #af8647', width: 'max-content', cursor: 'pointer', fontSize: '15px' }}>Thay đổi thời gian</div>
                            </div>}
                            {isForm === false &&

                                <div className="content-right">
                                    <div className="title">Lên lịch một chuyến tham quan</div>
                                    <div className="text">Chúng tôi rất muốn giới thiệu cho bạn bất động sản tuyệt đẹp của chúng tôi. Vui lòng chọn ngày và giờ bạn muốn bên dưới. Một đại lý sẽ sớm liên hệ để xác nhận cuộc hẹn của bạn.</div>
                                    <div className="form-group">
                                        <div className='select-div' >
                                            {/* <select className="custom-select" value={form.time} onClick={() => setIsTime(!isTIme)}>
                                                <option value="">Chọn thời gian</option>
                                                <option value="8h Sáng">8h Sáng</option>
                                                <option value="9h Sáng">9h Sáng</option>
                                                <option value="10h Sáng">10g Sáng</option>
                                                <option value="11h Trưa">11h Trưa</option>
                                                <option value="12h Trưa">12h Trưa</option>
                                                <option value="1h Chiều">1h Chiều</option>
                                            </select> */}
                                            <div className="custom-select" onBlur={() => handleBlur('time')} onClick={() => setIsTime(!isTIme)}>{form.time === '' ? 'Chọn thời gian' : form.time}</div>
                                            <div className={isTIme ? "dropdown-select open-select" : "dropdown-select"}>
                                                <div className='option-select' onClick={() => {handleChange('time', ''); setIsTime(!isTIme)}}>Chọn thời gian</div>
                                                <div className='option-select' onClick={() => {handleChange('time', '8h Sáng'); setIsTime(!isTIme)}}>8h Sáng</div>
                                                <div className='option-select' onClick={() => {handleChange('time', '9h Sáng'); setIsTime(!isTIme)}}>9h Sáng</div>
                                                <div className='option-select' onClick={() => {handleChange('time', '10h Sáng'); setIsTime(!isTIme)}}>10g Sáng</div>
                                                <div className='option-select' onClick={() => {handleChange('time', '11h Trưa'); setIsTime(!isTIme)}}>11h Trưa</div>
                                                <div className='option-select' onClick={() => {handleChange('time', '12h Trưa'); setIsTime(!isTIme)}}>12h Trưa</div>
                                                <div className='option-select' onClick={() => {handleChange('time', '1h Chiều'); setIsTime(!isTIme)}}>1h Chiều</div>
                                            </div>
                                        </div>
                                    </div>
                                    <Slider {...settings2}>
                                        {getNextFiveDays().map((day, index) => (
                                            <div className="date-meeting" key={index}>
                                                <div className="box" onClick={() => handleChange('date', `${day.dayOfWeek}, ngày ${day.day} ${day.month} năm ${day.year}`)} style={{ borderColor: form.date === `${day.dayOfWeek}, ngày ${day.day} ${day.month} năm ${day.year}` ? '#bc8f45' : '#ccc' }}>
                                                    <div className="sub-title">{day.dayOfWeek}</div>
                                                    <div className="title">{day.day}</div>
                                                    <div className="sub-title">{day.month}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                    <div className="button-group">
                                        <div className="button-div">
                                            <button className='custom-button' onClick={() => handleChange('method', 'offline')} style={{ borderColor: form.method === 'offline' ? '#bc8f45' : 'grey', backgroundColor: form.method === 'offline' ? '#bc8f45' : 'unset', color: form.method === 'offline' ? 'white' : 'unset' }}>Trực tiếp</button>
                                        </div>
                                        <div className="button-div">
                                            <button className='custom-button' onClick={() => handleChange('method', 'online')} style={{ borderColor: form.method === 'online' ? '#bc8f45' : 'grey', backgroundColor: form.method === 'online' ? '#bc8f45' : 'unset', color: form.method === 'online' ? 'white' : 'unset' }}>Gọi điện thoại</button>
                                        </div>
                                    </div>
                                    <div className="button-group">
                                        <div className="button-div">
                                            <button className='custom-button' onClick={() => setIsForm(!isForm)} style={{ borderRadius: 15 }}>Bước tiếp theo <i className='fa fa-arrow-right'></i></button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="section-9-kld2112">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item animate-section">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <span className='e1st'>01.</span>
                                    <span className='e2nd'>Nội thất</span>
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="left">Tổng số phòng ngủ </div>
                                    <div className="right">4</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Tổng số phòng tắn </div>
                                    <div className="right">4</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Phòng tắm đầy đủ</div>
                                    <div className="right">4</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Phòng giặt ủi</div>
                                    <div className="right">Tòa nhà bên trong, Chung trên sàn, Khu vực chung, Bên trong, Tủ giặt, Trong đơn vị</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Thiết bị gia dụng</div>
                                    <div className="right">Lò nướng gas âm tường, Bếp gas âm tường, Tủ lạnh quầy bar, Tủ lạnh âm tường, Bếp nấu, Máy sấy, Máy giặt/Máy sấy xếp chồng, Máy rửa chén, Máy giặt</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Các tính năng nội thất khác</div>
                                    <div className="right">Thang máy</div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item animate-section">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <span className='e1st'>02.</span>
                                    <span className='e2nd'>Ngoại thất & Xây dựng</span>
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="left">Những câu chuyện</div>
                                    <div className="right">46</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Tính năng lô đất</div>
                                    <div className="right">Vườn</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Các tính năng bên ngoài khác</div>
                                    <div className="right">Sân bóng rổ, Tòa nhà Sân bóng rổ, Tòa nhà Sân chơi, Tòa nhà Sân, Tòa nhà Vườn, Sân, Vườn, Sân chơi</div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item animate-section">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    <span className='e1st'>03.</span>
                                    <span className='e2nd'>Lô đất & Diện tích</span>
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="left">Trạng thái</div>
                                    <div className="right">Chưa giải quyết</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Khu vực sinh hoạt</div>
                                    <div className="right">2.900 feet vuông</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Kích thước lô đất</div>
                                    <div className="right">0,25 mẫu Anh</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Mã số MLS®</div>
                                    <div className="right">PRCH-8373840</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Kiểu</div>
                                    <div className="right">Căn hộ</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Năm xây dựng </div>
                                    <div className="right">1989</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Hàng xóm</div>
                                    <div className="right">Phía Đông Thượng</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Xem Mô tả</div>
                                    <div className="right">Thành phố</div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item animate-section">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    <span className='e1st'>04.</span>
                                    <span className='e2nd'>Tài chính</span>
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="left">Giá bán</div>
                                    <div className="right">3.775.000 đô la</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Bồi thường cho đại lý mua hàng</div>
                                    <div className="right">3%</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Thuế bất động sản</div>
                                    <div className="right">$56,748/năm</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Phí HOA</div>
                                    <div className="right">$3,477/tháng</div>
                                </div>
                                <div className="accordion-body">
                                    <div className="left">Phân vùng</div>
                                    <div className="right">C1-5/R10</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-6-kld2112">
                    <div className="section-6-1-kld2112">
                        <div className="col-md-8 left">
                            <div className="title animate-section">Máy tính thế chấp</div>
                            <div className="sub-title animate-section">Ước tính khoản thanh toán thế chấp hàng tháng của bạn, bao gồm tiền gốc và lãi, thuế tài sản và HOA. Điều chỉnh các giá trị để tạo ra mức lãi suất chính xác hơn.</div>
                            <div className="box-input">
                                <div className='input-cacul animate-section'>
                                    <label>Giá nhà ( $ )</label>
                                    <input type="text" value={price} disabled placeholder='Giá nhà' />
                                </div>
                                <div className='input-cacul animate-section' style={{ display: 'flex', flexDirection: 'row' }}>
                                    <span style={{ width: '70%' }}>
                                        <label>Trả trước ( $ )</label>
                                        <input style={{ width: '100%', padding: '25px 20px' }} type="text" value={downPayment} onChange={handleDownPaymentChange} placeholder='Giá nhà' />
                                    </span>
                                    <span style={{ width: '30%' }}>
                                        <label>( % )</label>
                                        <input style={{ width: '100%', padding: '25px 20px' }} type="text" value={downPaymentRate} onChange={handleDownPaymentRateChange} placeholder='Giá nhà' />
                                    </span>
                                </div>
                                <div className='input-cacul animate-section'>
                                    <label>Trả góp ( năm )</label>
                                    <div className="form-group">
                                        <div className='select-div' onBlur={() => handleBlurPaymentYear('tra-gop')}>
                                            <select className="custom-select" value={loanTerm} onClick={() => setIsPaymentYear(!isPaymentYear)}>
                                                <option value="10">10 năm</option>
                                                <option value="15">15 năm</option>
                                                <option value="20">20 năm</option>
                                                <option value="25">25 năm</option>
                                                <option value="30">30 năm</option>
                                            </select>
                                            <div className={isPaymentYear ? "dropdown-select open-select" : "dropdown-select"}>
                                                <div className='option-select' onClick={(e) => setLoanTerm(10)}>10 năm</div>
                                                <div className='option-select' onClick={(e) => setLoanTerm(15)}>15 năm</div>
                                                <div className='option-select' onClick={(e) => setLoanTerm(20)}>20 năm</div>
                                                <div className='option-select' onClick={(e) => setLoanTerm(25)}>25 năm</div>
                                                <div className='option-select' onClick={(e) => setLoanTerm(30)}>30 năm</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='input-cacul animate-section'>
                                    <label>Lãi suất ( % )</label>
                                    <input type="percent" value={interestRate} onChange={handleRateChange} placeholder='Giá nhà' />
                                </div>
                                <div className='input-cacul animate-section'>
                                    <label>Thuế tài sản ( $ )</label>
                                    <input type="text" value={taxes} onChange={handleTaxesChange} placeholder='Giá nhà' />
                                </div>

                                <div className='input-cacul animate-section'>
                                    <label>Phí HOA ( $ )</label>
                                    <input type="text" value={feeFlower} onChange={handleFeeFlowerChange} placeholder='Giá nhà' />
                                </div>


                            </div>
                        </div>
                        <div className="col-md-4 right">
                            <div className="title animate-section">$<CountUp end={parseFloat(unformatNumber(feeFlower)) + parseFloat(unformatNumber(taxes)) + Math.round(monthlyPayment)} /></div>
                            <div className="sub-title animate-section">Bạn cần thành toán hàng tháng</div>
                            <div className="box-percent">
                                <div className="cost-percent animate-section">
                                    <span className='cost-percent-left'>Tiền gốc và lãi</span>
                                    <span className='cost-percent-right'>$<CountUp end={Math.round(monthlyPayment)} /> ( <CountUp end={(Math.round(monthlyPayment) / (parseFloat(unformatNumber(feeFlower)) + parseFloat(unformatNumber(taxes)) + Math.round(monthlyPayment)) * 100).toFixed()} />% )</span>
                                </div>
                                <div className="cost-percent animate-section">
                                    <span className='cost-percent-left'>Thuế tài sản</span>
                                    <span className='cost-percent-right'>$<CountUp end={parseFloat(unformatNumber(taxes))} />( <CountUp end={(parseFloat(unformatNumber(taxes)) / (parseFloat(unformatNumber(feeFlower)) + parseFloat(unformatNumber(taxes)) + Math.round(monthlyPayment)) * 100).toFixed()} /> %)</span>
                                    {/* unformatNumber(taxes) */}
                                </div>
                                <div className="cost-percent animate-section">
                                    <span className='cost-percent-left'>Phí HOA</span>
                                    <span className='cost-percent-right'>$<CountUp end={parseFloat(unformatNumber(feeFlower))} /> ( <CountUp end={(parseFloat(unformatNumber(feeFlower)) / (parseFloat(unformatNumber(feeFlower)) + parseFloat(unformatNumber(taxes)) + Math.round(monthlyPayment)) * 100).toFixed()} /> %)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-10-kld2112 animate-section">
                    <img src="https://res.cloudinary.com/dolydpat4/image/upload/v1735207120/sob0azpfldnruwxjqfbm_q2f3rh.avif" alt="" />
                </div>
            </div>
            <FooterKLD2112 />
        </>
    );
}

export default Index;
