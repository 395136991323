import React, { useEffect, useState } from 'react';
import Source from 'components/Homepage/Analysis/XeGhep/Source'
import Access from 'components/Homepage/Analysis/XeGhep/Access'
import Touches from 'components/Homepage/Analysis/XeGhep/Touches'
import 'assets/Homepage/scss/analysis.scss'
import {
    fetchAnalysisXeGhep
} from 'Apis'

const processDataForChart = (data) => {
    const aggregatedData = {
        "Quảng cáo Google": 0,
        "Google tìm kiếm": 0,
        "Nguồn khác": 0,
    };

    data.forEach((item) => {
        const source = item.source;
        if (source === "Google Ads") {
            aggregatedData["Quảng cáo Google"] += 1;
        } else if (source === "Google Search") {
            aggregatedData["Google tìm kiếm"] += 1;
        } else {
            aggregatedData["Nguồn khác"] += 1;
        }
    });

    return Object.entries(aggregatedData).map(([key, value]) => ({
        source: key,
        value: value,
    }));
};

const processDataForChartAccess = (data) => {
    const aggregatedData = {
        "Hà Nội": 0,
        "Bắc Ninh": 0,
        "Bắc Giang": 0,
        "Tỉnh khác": 0,
    };

    data.forEach((item) => {
        const location = item.location;
        if (location === "VN - Hanoi") {
            aggregatedData["Hà Nội"] += 1;
        } else if (location === "VN - Bắc Ninh") {
            console.log(`Location: "${location}"`); // Xác nhận đã vào đây
            aggregatedData["Bắc Ninh"] += 1; // Phải đảm bảo dòng này được thực thi
        } else if (location === "VN - Bắc Giang") {
            aggregatedData["Bắc Giang"] += 1;
        } else if (location) {
            aggregatedData["Tỉnh khác"] += 1;
        } else {
            console.log("Unhandled location:", item); // Kiểm tra các đối tượng không khớp
        }
    })

    return Object.entries(aggregatedData).map(([key, value]) => ({
        location: key,
        value: value,
    }));
};

const processDataForChartInteractions = (data) => {
    const aggregatedData = {
        "Gọi điện thoại": 0,
        "Truy cập Zalo": 0,
    };

    data.forEach((item) => {
        if (item.accessDetails && Array.isArray(item.accessDetails)) {
            item.accessDetails.forEach((detail) => {
                if (detail.url === "call") {
                    aggregatedData["Gọi điện thoại"] += 1;
                } else if (detail.url === "zalo") {
                    aggregatedData["Truy cập Zalo"] += 1;
                }
            });
        }
    });

    return Object.entries(aggregatedData).map(([key, value]) => ({
        action: key,
        value: value,
    }));
};

const getInitialDates = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Đặt giờ về 00:00 ngày hôm nay
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1); // Đặt ngày mai

    // Format ngày theo giờ địa phương
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    return {
        dateFirst: formatDate(today), // Kết quả sẽ là "2025-01-11 00:00:00"
        dateSecond: formatDate(tomorrow), // Kết quả sẽ là "2025-01-12 00:00:00"
    };
};

const Index = () => {
    const [dates, setDates] = useState(getInitialDates());
    const [chartData, setChartData] = useState([]);
    const [chartDataAccess, setChartDataAccess] = useState([]);
    const [chartDataInteractions, setChartDataInteractions] = useState([]);
    // Hàm chuyển ngày trước đó
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    const handlePrevDay = () => {
        const newDateFirst = new Date(dates.dateFirst);
        const newDateSecond = new Date(dates.dateSecond);
        newDateFirst.setDate(newDateFirst.getDate() - 1);
        newDateSecond.setDate(newDateSecond.getDate() - 1);
        setDates({
            dateFirst: formatDate(newDateFirst),
            dateSecond: formatDate(newDateSecond),
        });
    };
    const handleNextDay = () => {
        const newDateFirst = new Date(dates.dateFirst);
        const newDateSecond = new Date(dates.dateSecond);
        newDateFirst.setDate(newDateFirst.getDate() + 1);
        newDateSecond.setDate(newDateSecond.getDate() + 1);
        setDates({
            dateFirst: formatDate(newDateFirst),
            dateSecond: formatDate(newDateSecond),
        });
    };
    useEffect(() => {
        fetchAnalysisXeGhep(dates)
            .then((result) => {
                const processedData = processDataForChart(result);
                const processedDataAccess = processDataForChartAccess(result);
                const processedDataInteractions = processDataForChartInteractions(result);
                setChartData(processedData);
                setChartDataAccess(processedDataAccess);
                setChartDataInteractions(processedDataInteractions);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [dates]);
    return (
        <div className='website-analysis'>
            <div className="col-md-6 total-analysis">
                <div className="data-analysis">
                <div className="above">
                        XE GHÉP BẮC GIANG - BẮC NINH - HÀ NỘI
                    </div>
                    <div className="above">
                        Tổng lượt truy cập hôm nay
                    </div>
                    <div className="title">
                        <div className="pre-button" onClick={handlePrevDay}>
                            &lt;
                        </div>
                        <div className="text">{dates.dateFirst.split(" ")[0]}</div>
                        <div className="next-button" onClick={handleNextDay}>
                            &gt;
                        </div>
                    </div>
                    <div className="under">
                        {chartData.reduce((total, item) => total + item.value, 0)}
                    </div>

                </div>
            </div>
            <div className="col-md-6">
                <div className="title-chart">
                    <div className="text">Lượt truy cập</div>
                    
                </div>
                <Source data={chartData} />
            </div>
            <div className="col-md-6">
                <div className="title-chart">
                    <div className="text">Vị trí truy cập</div>
                    
                </div>
                <Access data={chartDataAccess} />
            </div>
            <div className="col-md-6">
                <div className="title-chart">
                    <div className="text">Lượt tương tác</div>
                    
                </div>
                <Touches data={chartDataInteractions} />
            </div>
        </div>
    );
}

export default Index;
