import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { API_ROOT } from 'utils'
import {
    fetchTemplate
} from 'Apis'
// Tạo Context
const AppContext = createContext();

// Provider
const AppProvider = ({ children }) => {
    const [productList, setProductList] = useState([
        {
            category: "website-bat-dong-san",
            id: "KLD2112",
            img: "https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/bds_m0dtnd.png",
            name: "mẫu bất động sản DC - KLD2112",
            price: "Giá liên hệ",
            src: "/template/KLD2112",
            src1: "https://kassdev.com/website/template/KLD2112/",
            time: "4 - 7",
            title: "Bất động sản DC",
            type: "Cá nhân & Tổ chức",
            view: 7,
        },

        {
            category: "website-nha-hang-quan-ca-phe",
            id: "KLD1812",
            img: "https://res.cloudinary.com/dolydpat4/image/upload/v1735832435/coffee_agfejm.png",
            name: "mẫu website coffee - tiệm bánh",
            price: "Giá liên hệ",
            src: "/template/KLD1812",
            src1: "https://kassdev.com/website/template/KLD1812",
            time: "4 - 7",
            title: "Kass Café",
            type: "Cá nhân & Tổ chức",
            view: 3,
        },

        {
            id: "KLD0003",
            img: "https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/xg_ae1cu0.png",
            src1: "https://www.xeghepbacgiangbacninhhanoi.net",
            src: "/template/KLD0003",
            name: "mẫu landing page xe ghép - KLD0003",
            title: "Xe ghép đức anh",
            view: 2,
            time: "4 - 7",
            price: "Giá liên hệ",
            type: "Cá nhân & Tổ chức",
            category: "landing-page",
        },

        {
            id: "KLD0001",
            img: "https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/ktech_wocptp.png",
            src1: "https://ktech-uw1a.onrender.com/",
            src: "/template/KLD0001",
            name: "mẫu website thương mai điện tử - KLD0001",
            title: "Ktech commerce",
            view: 1,
            time: "4 - 7",
            price: "Giá liên hệ",
            type: "Cá nhân & Tổ chức",
            category: "website-thuong-mai-dien-tu",
        },
        {
            id: "KLD0002",
            img: "https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/db_oakvr6.png",
            src1: "https://ktech-dashboard.onrender.com/v2/",
            src: "/template/KLD0002",
            name: "mẫu dashboard - KLD0002",
            title: "Ktech dashboard",
            view: 2,
            time: "4 - 7",
            price: "Giá liên hệ",
            type: "Cá nhân & Tổ chức",
            category: "website-dashboard",
        },
        {
            id: "KLD1312",
            img: "https://res.cloudinary.com/dolydpat4/image/upload/v1735832435/edu_xexkdo.png",
            src1: "https://kassdev.com/website/template/KLD1312",
            src: "/template/KLD1312",
            name: "mẫu website trường học & bán khoá học - KLD1312",
            title: "trường đại học - cao đẳng",
            view: 3,
            time: "4 - 7",
            price: "Giá liên hệ",
            type: "Cá nhân & Tổ chức",
            category: "website-giao-duc",
        },
        {
            id: "KLD2812",
            img: "https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/wedding_vqeipg.png",
            src1: "https://kassdev.com/website/template/KLD2812",
            src: "/template/KLD2812",
            name: "Mẫu website studio - wedding ( lễ cưới ) - KLD2812",
            title: "John & Diana",
            view: 4,
            time: "4 - 7",
            price: "Giá liên hệ",
            type: "Cá nhân & Tổ chức",
            category: "studio-chup-anh-cuoi",
        },

    ]);
    const [dataIp, setDataIp] = useState(null);
    const fetchPostIP = async (data) => {
        try {
            const req = await axios.post(`${API_ROOT}/v1/ip/ipUser`, data);
            return req.data;
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };
    useEffect(() => {
        function generateRandomSectionId(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        }
        const date = new Date();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const time = `${hours}:${minutes}`;
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const today = `${year}-${month}-${day}`;
        const urlParams = new URLSearchParams(window.location.search);
        const gclid = urlParams.get('gclid');
        const referrer = document.referrer;

        const initialDataIp = {
            sectionId: generateRandomSectionId(64),
            source: '',
            date: today,
            time: time,
            platform: navigator.platform,
            urlVisit: '',
        };
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (gclid) {
            initialDataIp.source = 'Google Ads';
            localStorage.setItem('source', 'Google Ads');
        } else if (referrer && referrer.includes('google.com')) {
            initialDataIp.source = 'Google Search';
            localStorage.setItem('source', 'Google Search');
        } else if (referrer && referrer.includes('facebook.com')) {
            initialDataIp.source = 'Facebook';
            localStorage.setItem('source', 'Google Search');
        }  else if (/FBAN|FBAV/i.test(userAgent)) {
            initialDataIp.source = 'Facebook In-App';
        } else {
            initialDataIp.source = 'Other';
            localStorage.setItem('source', 'Other');
        }

        setDataIp(initialDataIp);

        fetchTemplate()
            .then((result) => {
                setProductList(result.data)
            }).catch((err) => {
                console.log(err);
            });
    }, []);
    const [ref, setRef] = useState('')

    return (
        <AppContext.Provider value={{
            dataIp,
            fetchPostIP,
            productList,
            setProductList,
            ref,
            setRef
        }}>
            {children}
        </AppContext.Provider>
    );
};

// Hook để sử dụng AppContext
const useApp = () => useContext(AppContext);

// Xuất khẩu mặc định
export default AppProvider;
export { useApp };
