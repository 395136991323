import React, { useState, useEffect, memo, useRef } from 'react'
import 'assets/Homepage/scss/header.scss'
import signature from 'assets/Homepage/images/logo3.svg'
import { NavLink } from 'react-router-dom'
import Phone from 'assets/Homepage/images/phone-call-svgrepo-com.svg'
import Zalo from 'assets/Homepage/images/zalo-svgrepo-com.svg'
import Logo from 'assets/Homepage/images/logooo.svg'
const Index = ({ isLoading }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }
    const [isSelectService, setIsSelectService] = useState(false)
    const [isSelectLibrary, setIsSelectLibrary] = useState(false)
    const dpkCursorRef = useRef(null);
    const dpkCursorMouse = useRef({ x: -100, y: -100 });
    const dpkCursorPos = useRef({ x: 0, y: 0 });
    const speedOption = 0.25
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        let ticking = false;

        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setScrolled(isScrolled);

            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const scrollY = window.scrollY;
                    const topLeft = document.querySelector(".dot.top-left");
                    const bottomLeft = document.querySelector(".dot.bottom-left");
                    const topRight = document.querySelector(".dot.top-right");
                    const bottomRight = document.querySelector(".dot.bottom-right");
                    const cycle = 2000;
                    const moveDistance = 200;

                    // Tính toán vị trí theo chu kỳ
                    const offset = (scrollY % cycle) / (cycle / 2);
                    const leftRightOffset = offset <= 1 ? offset * moveDistance : (2 - offset) * moveDistance;

                    // Áp dụng cho cặp trái (di chuyển phải)
                    if (topLeft && bottomLeft) {
                        topLeft.style.transform = `translateX(${leftRightOffset}px)`;
                        bottomLeft.style.transform = `translateX(${-leftRightOffset}px)`;
                    }

                    // Áp dụng cho cặp phải (di chuyển trái)
                    if (topRight && bottomRight) {
                        topRight.style.transform = `translateX(${leftRightOffset}px)`;
                        bottomRight.style.transform = `translateX(${-leftRightOffset}px)`;
                    }

                    ticking = false;
                });
                ticking = true;
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-top-1s",
                ".animate-kassdev-right-1s",
                ".animate-kassdev-left-1s",
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-top-2s",
                ".animate-kassdev-right-2s",
                ".animate-kassdev-left-2s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-top-3s",
                ".animate-kassdev-right-3s",
                ".animate-kassdev-left-3s",
                ".animate-kassdev-bottom-3s",
                ".animate-kassdev-top-4s",
                ".animate-kassdev-right-4s",
                ".animate-kassdev-left-4s",
                ".animate-kassdev-bottom-4s",
                ".animate-kassdev-top-5s",
                ".animate-kassdev-right-5s",
                ".animate-kassdev-left-5s",
                ".animate-kassdev-bottom-5s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);
    useEffect(() => {
        const updatePosition = () => {
            dpkCursorPos.current = {
                x: dpkCursorPos.current.x + (dpkCursorMouse.current.x - dpkCursorPos.current.x) * speedOption,
                y: dpkCursorPos.current.y + (dpkCursorMouse.current.y - dpkCursorPos.current.y) * speedOption
            }
            if (dpkCursorRef.current) { // Kiểm tra xem dpkCursorRef.current có tồn tại không
                dpkCursorRef.current.style.transform = `translate3d(calc(${dpkCursorPos.current.x}px - 50%), calc(${dpkCursorPos.current.y}px - 50%), 0)`;
            }
        };

        const handleMouseMove = (e) => {
            dpkCursorMouse.current = { x: e.clientX, y: e.clientY };
        };
        const handleCursorChange = (e) => {
            const tgt = e.target;
            const computed = window.getComputedStyle(tgt)["cursor"];

            // Kiểm tra nếu `dpkCursorRef.current` không phải null trước khi thao tác
            if (dpkCursorRef.current) {
                if (computed === "pointer") {
                    dpkCursorRef.current.classList.add("dpkCursor-hover");
                } else {
                    dpkCursorRef.current.classList.remove("dpkCursor-hover");
                }
            } else {
                console.warn("dpkCursorRef.current is null");
            }
        };

        const loop = () => {
            updatePosition();
            requestAnimationFrame(loop);
        };
        window.addEventListener('mouseover', handleCursorChange);
        window.addEventListener("mousemove", handleMouseMove);
        loop();

        return () => {
            window.removeEventListener('mouseover', handleCursorChange);
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [speedOption]);
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);
    const zaloLink = isIOS ? 'zalo://qr/p/b0vaft11czp1' : isAndroid ? 'https://zaloapp.com/qr/p/b0vaft11czp1' : 'zalo://conversation?phone=0379382992';

    return (
        <>
            <div className={isIOS ? 'hotline-icon-one-ios' : isAndroid ? 'hotline-icon-one-android' : 'hotline-icon-one-pc'}>
                <a href={zaloLink} target='blank'>
                    <img src={Zalo} className='hotline-icon-svg' alt="Logo" />
                </a>
            </div>
            <div className='hotline-icon-two'>
                <a href="tel:0379382992"><img src={Phone} className='hotline-icon-svg' alt="Logo" /></a>
            </div>
            <div ref={dpkCursorRef} className="dpkCursor " />
            <div className=''>
                <style>
                    {`
          @import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");
          * {
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              font-family: "Play", sans-serif;
          }
          body {
            background-color:black;
              height: 100vh;
              width: 100%;
              font-size: 16px;
              margin: auto;
              color: black;
          }
          .play-regular {
              font-family: "Play", sans-serif;
              font-weight: 400;
              font-style: normal;
          }
          .play-bold {
              font-family: "Play", sans-serif;
              font-weight: 700;
              font-style: normal;
              letter-spacing: 1px;
          }
        `}
                </style>
                <div className={scrolled ? 'header-sell-website header-sell-website-scroll' : 'header-sell-website '}>
                    <nav className="navbar-sell-website play-bold">
                        <i className="hamburger-icon hamburger-btn fa-solid fa-bars" style={{ fontSize: 20 }} onClick={toggleMenu}></i>
                        <NavLink to='/' className="logo animate-kassdev-left-1s">
                            <img src={signature} alt="logo" />
                        </NavLink>
                        <ul className={isMenuOpen ? "links show-menu" : "links"}>
                            <span className="close-btn material-symbols-rounded animate-kassdev-top-1s" onClick={toggleMenu}>x</span>

                            <li className='animate-kassdev-top-1s' ><NavLink to='/' onClick={toggleMenu}>Trang chủ</NavLink></li>
                            <li className="dropdown animate-kassdev-top-2s" style={{ cursor: 'pointer' }}>
                                <a href >Dịch vụ  <span style={{ transform: 'scaleX(1.5)', display: 'inline-block' }}>&#8744;</span></a>
                                <ul className='dropdown-menu deactivate-portfolio-false play-bold' >
                                    <NavLink to='/service/bao-tri-sua-chua-website' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Bảo trì/sửa chữa website
                                    </NavLink>
                                    <NavLink to='/service/thiet-ke-website-rieng' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Tư vấn, thiết kế website riêng
                                    </NavLink>
                                    <NavLink to='/service/ten-mien-website-domain' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Tên miền website (Domain)
                                    </NavLink>
                                    <NavLink to='/service/qua-trinh-thuc-hien' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Quá trình làm việc
                                    </NavLink>
                                </ul>
                            </li>
                            <li className="dropdown animate-kassdev-top-3s" style={{ cursor: 'pointer' }}>
                                <a href >Kho giao diện <span style={{ transform: 'scaleX(1.5)', display: 'inline-block' }}>&#8744;</span></a>
                                <ul className='dropdown-menu deactivate-portfolio-false play-bold' >

                                    <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Landing Page
                                    </NavLink>
                                    <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Studio - Chụp ảnh cưới
                                    </NavLink>
                                    <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Website bất động sản
                                    </NavLink>
                                    <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Website thương mại điện tử nhỏ
                                    </NavLink>
                                    <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Website nhà hàng/quán cà phê
                                    </NavLink>
                                    <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Website giáo dục
                                    </NavLink>
                                    <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        Website dashboard
                                    </NavLink>
                                </ul>
                            </li>
                            <li className='animate-kassdev-top-4s'><NavLink to={'/blog'} onClick={toggleMenu}>Blogs</NavLink></li>
                            <li className='animate-kassdev-top-5s'><NavLink to={'/aboutMe'} onClick={toggleMenu}>Về tôi</NavLink></li>
                        </ul>
                        <a href="tel:0379382992" className="login-btn animate-kassdev-right-2s" style={{ textDecoration: 'none', color: 'white' }}>Liên hệ</a>
                    </nav>
                </div>
                <div className='row mobile-header'>
                    <div className='col-4 icon-menu'>
                        <div id="checkbox2" className={isMenuOpen ? 'checked' : ''}></div>
                        <label class="toggle toggle2" for="checkbox2" onClick={toggleMenu} style={{ color: 'white' }} >
                            <div id="bar4" class="bars"></div>
                            <div id="bar5" class="bars"></div>
                            <div id="bar6" class="bars"></div>
                        </label>
                    </div>
                    <div className='col-4 logo-mobile'><img src={Logo} alt='logo' /></div>
                    <a href="tel:0379382992" className='col-4 hotline' style={{ color: 'white', textDecoration: 'none' }}><div className='ele'>Liên hệ</div></a>
                </div>
                <div className={isMenuOpen ? 'mobile-menu select-menu' : 'mobile-menu close-menu'}>
                    <div className='nav-mobile'>
                        <NavLink to={'/'}><i className='fa fa-home'></i> Trang chủ</NavLink>
                        <div >
                            <div className='nav-ser' style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setIsSelectService(!isSelectService)}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}><i style={{ width: 24 }} className='fa fa-wrench' alt='' />Dịch vụ</div>
                                <div><i alt='' className={isSelectService ? 'fa fa-chevron-right rotate-icon-menu' : 'fa fa-chevron-right rotate-icon-menu-reverse'} /></div>
                            </div>
                            <ul className={`sub-menu-homepage ${isSelectService ? 'open-homepage' : ''}`}>
                                <li><NavLink to={'/service/bao-tri-sua-chua-website'} ><li>Bảo trì/sửa chữa website</li></NavLink></li>
                                <li> <NavLink to={'/service/thiet-ke-website-rieng'} ><li>Tư vấn thiết kế website riêng</li></NavLink></li>
                                <li><NavLink to={'/service/ten-mien-website-domain'} ><li>Tên miền website(Domain)</li></NavLink></li>
                                <li><NavLink to={'/service/qua-trinh-thuc-hien'} ><li>Quá trình làm việc</li></NavLink></li>
                            </ul>
                        </div>
                        <div>
                            <div className='nav-lib' style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setIsSelectLibrary(!isSelectLibrary)}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}><i style={{ width: 24 }} className='fa fa-layer-group' alt='' />Kho giao diện</div>
                                <div><i alt='' className={isSelectLibrary ? 'fa fa-chevron-right rotate-icon-menu' : 'fa fa-chevron-right rotate-icon-menu-reverse'} /></div>
                            </div>
                            <ul className={`sub-menu-homepage ${isSelectLibrary ? 'open-homepage' : ''}`}>
                                <li><NavLink to={'/category/landing-page'} ><li>Landing page</li></NavLink></li>
                                <li><NavLink to={'/category/website-thuong-mai-dien-tu'} ><li>Website thương mại điện tử nhỏ</li></NavLink></li>
                                <li><NavLink to={'/category/website-dashboard'} ><li>Website dashboard</li></NavLink></li>
                                <li><NavLink to={'/category/studio-chup-anh-cuoi'} ><li>Studio - Chụp ảnh cưới</li></NavLink></li>
                                <li><NavLink to={'/category/website-bat-dong-san'} ><li>Website bất động sản</li></NavLink></li>
                                <li><NavLink to={'/category/website-nha-hang-quan-ca-phe'} ><li>Website Nhà Hàng - Quán Cà Phê</li></NavLink></li>
                                <li><NavLink to={'/category/website-giao-duc'} ><li>Website Giáo Dục</li></NavLink></li>
                            </ul>
                        </div>
                        <NavLink to={'/blog'}><i className='fa fa-blog' alt='' />Blogs</NavLink>
                        <NavLink to={'/aboutMe'}><i className='fa fa-address-card' alt='' />Về tôi</NavLink>
                    </div>
                </div>
            </div>
            <div class="background-dot">
                <div class="dot top-left"></div>
                <div class="dot top-right"></div>
                <div class="dot bottom-left"></div>
                <div class="dot bottom-right"></div>
            </div>
        </>
    );
}

export default memo(Index);
