import React, { useState } from 'react';
import 'assets/List_Website/KLD2812/scss/header.scss';
import { useNavigate } from 'react-router-dom';

const Index = (props) => {
    const navigate = useNavigate();
    const { changePage, setChangePage } = props
    const [menuOpen, setMenuOpen] = useState(false); // Trạng thái mở/đóng menu
    const [imgHeader, setImgHeader] = useState('https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3926472471974ffd7b3_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-147.avif');
    const images = {
        default:
            'https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3926472471974ffd7b3_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-147.avif',
        realWedding:
            'https://cdn.prod.website-files.com/63170356296f85970dadc536/65a91bd64850e23f2f6c9126_MERKATOR-summer-22-26.avif',
        portfolio:
            'https://cdn.prod.website-files.com/63170356296f85970dadc536/63170356296f85fa5badc564_M-and-M-394.avif',
        photobook:
            'https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3bfd35f5a0924917409_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-116.jpg',
        aboutUs:
            'https://cdn.prod.website-files.com/63170356296f85970dadc536/647d8f2bd3205501100bcc07_Danilo-and-Sharon-luxury-wedding-photography-studio-in-Europe-118.avif',
        contact:
            'https://cdn.prod.website-files.com/63170356296f85970dadc536/65a91bd64850e23f2f6c9126_MERKATOR-summer-22-26.avif',
    };
    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Đảo ngược trạng thái menu
    };
    const handleClick = (url) => {
        window.location.hash = "#top";
        setChangePage(true)
        toggleMenu()
        setTimeout(() => {
            navigate(url);
            setChangePage(false)
        }, 900);
    };
    return (
        <>
            {changePage ? <div className="loader1-kld2112"></div> : <div className="loader-kld2112"></div>}
            <div className='header-kld2812'>
                <div className='desktop-kld2812'>
                    <div className="section-1">
                        John & <span>Diana</span>
                    </div>
                    <div className="section-2" onClick={toggleMenu}>
                        Menu
                    </div>
                </div>
                <div className={`nav-link ${menuOpen ? 'menu-show' : 'menu-hide'}`}>
                    <div className='list-kld2812'>
                        <div className="section-1">
                            John & <span>Diana</span>
                        </div>
                        <div className="section-2" onClick={toggleMenu}>
                            Menu
                        </div>
                    </div>
                    <div className="section-3">
                        <a className="nav-item" onClick={() => handleClick('/website/template/KLD2812/real-wedding')} onMouseEnter={() => setImgHeader(images.realWedding)} onMouseLeave={() => setImgHeader(images.default)}>Đám cưới</a>
                        <a className="nav-item" onClick={() => handleClick('/website/template/KLD2812/portfolio')} onMouseEnter={() => setImgHeader(images.portfolio)} onMouseLeave={() => setImgHeader(images.default)}>danh mục</a>
                        <div className="nav-item"><img src={imgHeader} alt="" /></div>
                        <a className="nav-item" onClick={() => handleClick('/website/template/KLD2812/photo-book')} onMouseEnter={() => setImgHeader(images.photobook)} onMouseLeave={() => setImgHeader(images.default)}>sách ảnh</a>
                        <a className="nav-item" onClick={() => handleClick('/website/template/KLD2812/about')} onMouseEnter={() => setImgHeader(images.aboutUs)} onMouseLeave={() => setImgHeader(images.default)}>về chúng tôi</a>
                        <a className="nav-item" onClick={() => handleClick('/website/template/KLD2812/contact')} onMouseEnter={() => setImgHeader(images.contact)} onMouseLeave={() => setImgHeader(images.default)}>Liên hệ</a>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Index;
