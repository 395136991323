import React, { useEffect, useState } from 'react';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import 'assets/List_Website/KLD2812/scss/real.scss'
import HeaderKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Header'
import FooterKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Footer'
const Index = () => {
    const [changePage, setChangePage] = useState(false)
    useEffect(() => {
        const sections = document.querySelectorAll(".animate-section");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate");
                    }
                });
            },
            { threshold: 0.3 }
        );

        sections.forEach((section) => observer.observe(section));
        return () => observer.disconnect();
    }, []);
    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    return (
        <>
            <style>
                {`
            body{
                background-color: #f3f3f3;
            }
            `}
            </style>
            <HeaderKLD2812 setChangePage={setChangePage} changePage={changePage} />
            <div className='real-kld2812' style={{ opacity: changePage ? 0 : 1, transition: 'all 0.4s' }}>
                <div className="section-1-kld2812" >
                    <div className='introduce'>
                        <div className='title animate-section'>Một đám cưới thực sự</div>
                        <div className="sub-title animate-section">MỖI CÂU CHUYỆN ĐÁM CƯỚI ĐỀU ĐẶC BIỆT</div>
                        <div className="sub-title animate-section">ĐÂY CHỈ LÀ MỘT SỐ TRONG SỐ ĐÓ</div>
                    </div>
                </div>
                <div className="section-2-kld2812">
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Jessica & Daniel</div>
                            <div className="text">Một đám cưới đích thực vượt thời gian tại Villa Pizzo, Hồ Como</div>
                            <div className="sub-text">Biệt thự Pizzo, Hồ Como - 2024</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/6756c2db1c80434774be764c_villa-pizzo-lake-como-extraordinary-wedding-23.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3926472471974ffd7b3_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-147.avif' alt="" />
                        </div>
                    </div>
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Jamie & Nikolas</div>
                            <div className="text">Đám cưới sang trọng theo phong cách cổ điển tại Cung điện Badrutt của St. Moritz</div>
                            <div className="sub-text">Cung điện Badrutt St Moritz - 2024</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/674d8fad4ebb912d300a6d16_J-and-N-wedding-126-2.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/6752e3bfd61a1d0efd62a149_villa-cimbrone-ravello-amalfi-coast-wedding-elegant-12.avif' alt="" />
                        </div>
                    </div>
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Jordan & Anthony</div>
                            <div className="text">Ký ức bên hồ tại Villa Balbiano, Hồ Como</div>
                            <div className="sub-text">Biệt thự Balbiano, Hồ Como - 2024</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/67503970bbae0b4d3c962e8f_B-and-L-wedding-80.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/65af8a57fc8917fbb7095fa3_Danilo-and-Sharon-luxury-wedding-photography-153.avif' alt="" />
                        </div>
                    </div>
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Erica & Cat</div>
                            <div className="text">Một buổi tiệc dưới ánh nến ấm cúng tại Villa Cimbrone</div>
                            <div className="sub-text">Biệt thự Cimbrone - 2024</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/674edaa42c5fe975ef9d37e9_Sabrina-and-Edrick-121.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/675196028107fac284ee9f0e_villa-balbiano-wedding-lake-como-25.avif' alt="" />
                        </div>
                    </div>
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Bridgette & Lawrence</div>
                            <div className="text">Đám cưới tại Villa Cimbrone với tầm nhìn mang tính biểu tượng của Amalfi</div>
                            <div className="sub-text">Biệt thự Cimbrone - 2024</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/65ae4a35d2c6ecb99ec8b04f_Danilo-and-Sharon-luxury-wedding-photography-30.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/65aa3a610350fd3cdf7e9681_Danilo-and-Sharon-luxury-wedding-photography-7.avif' alt="" />
                        </div>
                    </div>
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Revital & Yam</div>
                            <div className="text">Đám cưới nhiều ngày tại Rome, biệt thự Miani</div>
                            <div className="sub-text">Villa Miani, Rome - 2023</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/64674af83d82451ca50d6422_L-and-B-1550.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/6752d185561b8e6a7219d44b_il-borgo-toscana-ferragamo-wedding-danilo-and-sharon-36.avif' alt="" />
                        </div>
                    </div>
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Sabrina & Edrick</div>
                            <div className="text">Kết nối truyền thống Indonesia và Ý tại Borgo Egnazia</div>
                            <div className="sub-text">Borgo Egnazia - 2024</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/675196028107fac284ee9f0e_villa-balbiano-wedding-lake-como-25.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/6756aa1cc5716533ed995da0_cap-estel-french-riviera-indian-wedding-lavender-and-rose-29.avif' alt="" />
                        </div>
                    </div>
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Luisa & Jonathan</div>
                            <div className="text">Đám cưới sang trọng tại Villa Balbiano mang tính biểu tượng, Hồ Como</div>
                            <div className="sub-text">Biệt thự Balbiano, Hồ Como - 2023</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/65a7ba84bb616e20e2d5014e_C-and-G-123.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/64760db9e2f0251ebfc35370_S-and-P-493.avif' alt="" />
                        </div>
                    </div>
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Helene & Janis</div>
                            <div className="text">Đám cưới ở Hồ Geneva tại Beau Rivage</div>
                            <div className="sub-text">Hồ Geneva - 2023</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/64761463bb6bf1d1b0cc8327_Elegant-Backyard-Wedding-Lommel-149.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/6454cefeeedde31d732b7676_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-244.avif' alt="" />
                        </div>
                    </div>
                    <div className="section-2-1">
                        <div className="col-md-7 left">
                            <div className="title">Lauren & Barry</div>
                            <div className="text">Cuối tuần cưới đầy phong cách ở Stellenbosch, Nam Phi</div>
                            <div className="sub-text">Quoin Rock, Stellenbosch SA - 2023</div>
                        </div>
                        <div className="col-md-5 right">
                            <img className='show-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/6454d121f10b72538a605ebe_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-92.avif' alt="" />
                            <img className='off-image' src='https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/65a7ba84bb616e20e2d5014e_C-and-G-123.avif' alt="" />
                        </div>
                    </div>
                </div>
                <div className="section-11-kld2812">
                    <div className="title animate-section">Dear John & Diana</div>
                    <div className="box-sent">
                        <div className=' animate-section text'>Tên tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Họ & Tên' />
                        <div className="text animate-section">và tôi đang lên kế hoạch</div>
                        <input type="text" className=' animate-section input-text' placeholder='Lễ cưới' />
                        <div className="text animate-section">trong</div>
                        <input type="text" className=' animate-section input-text' placeholder='Trung tâm' />
                        <div className="text animate-section">vào</div>
                        <input type="text" className=' animate-section input-text' placeholder='Ngày x tháng x năm xxxx' />
                        <div className="text animate-section">người lập kế hoạch đám cưới của tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Nguyễn Văn A' />
                        <div className="text animate-section">và tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='@Nguyễn Thị B' />
                        <div className="text animate-section">trên instagram</div>
                    </div>
                    <div className="btn-sent animate-section">Vui lòng chờ...</div>
                </div>
            </div>
            <FooterKLD2812 />
        </>
    );
}

export default Index;
