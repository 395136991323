import React, {useEffect} from 'react';
import 'assets/List_Website/KLD2812/scss/footer.scss'
const Index = () => {
    useEffect(() => {
        const sections = document.querySelectorAll(".animate-section");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate");
                    }
                });
            },
            { threshold: 0.3 }
        );

        sections.forEach((section) => observer.observe(section));
        return () => observer.disconnect();
    }, []);
    return (
        <div className='footer-kld2812'>
            <div className="section-1-kld2812">
                <div className="col-md-6 left">
                    <div className="title">Gửi email cho chúng tôi theo địa chỉ:</div>
                    <div className="sub-title">dongduclan277@gmail.com</div>
                    <div className="text">Người quản ký đặt phòng của chúng tôi là Lân thường phản hồi trong vòng chưa đầy 3 giờ.</div>
                </div>
                <div className="col-md-6 right">
                    <div className="nav-item-link">
                        <div className="col-md-6">Đám cưới thực sự</div>
                        <div className="col-md-6">Sách ảnh</div>
                        <div className="col-md-6">Danh mục đầu tư</div>
                        <div className="col-md-6">Khoá học Flash</div>
                        <div className="col-md-6">Về chúng tôi</div>
                        <div className="col-md-6">Dành cho nhiếp ảnh gia</div>
                        <div className="col-md-6">Liên hệ với chúng tôi</div>
                    </div>
                    <div className="brand">John & Diana</div>
                </div>
            </div>
        </div>
    );
}

export default Index;
