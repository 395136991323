import React, { useState, useEffect, useRef } from 'react';
import 'assets/Homepage/scss/Landing_page.scss';
import { NavLink, useLocation } from 'react-router-dom';
import Loading from 'components/Homepage/About/Loading';
import HeaderHomepage from 'components/Homepage/Header';
import FooterHomepage from 'components/Homepage/Footer';
import { Helmet } from 'react-helmet';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import {
    updateViewTemplate
} from 'Apis'
import { useApp } from 'components/Homepage/context/AppContext';
const Index = () => {
    const { dataIp, fetchPostIP, productList, setProductList } = useApp();
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const path = location.pathname
    const pageCategory = path.split("/").pop()
    const [product, setProduct] = useState([
        {
            id: 'KLD1312',
            img: 'https://res.cloudinary.com/dolydpat4/image/upload/v1735832435/edu_xexkdo.png',
            src1: 'https://kassdev.com/website/template/KLD1312',
            src: '/template/KLD1312',
            name: 'mẫu website trường học & bán khoá học - KLD1312',
            title: 'trường đại học - cao đẳng',
            view: 0,
            time: '4 - 7',
            price: 'Giá liên hệ',
            type: 'Cá nhân & Tổ chức',
            category: 'website-giao-duc'
        }
    ]);

    useEffect(() => {
        if (dataIp) {
            fetchPostIP({ ...dataIp, urlVisit: '/category/website-giao-duc' })
                .then(() => {
                    console.log('sent')
                })
        }
        const foundProducts = productList.filter(product => product.category === pageCategory);
        setProduct(foundProducts);
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, [dataIp]);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-top-1s",
                ".animate-kassdev-right-1s",
                ".animate-kassdev-left-1s",
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-top-2s",
                ".animate-kassdev-right-2s",
                ".animate-kassdev-left-2s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-top-3s",
                ".animate-kassdev-right-3s",
                ".animate-kassdev-left-3s",
                ".animate-kassdev-bottom-3s",
                ".animate-kassdev-top-4s",
                ".animate-kassdev-right-4s",
                ".animate-kassdev-left-4s",
                ".animate-kassdev-bottom-4s",
                ".animate-kassdev-top-5s",
                ".animate-kassdev-right-5s",
                ".animate-kassdev-left-5s",
                ".animate-kassdev-bottom-5s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);

    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    const handleViewTemplate = (id) => {
        updateViewTemplate(id)
            .then((result) => {
                // console.log(result)
                const updatedProductList = productList.map((product) =>
                    product.id === id
                        ? { ...product, ...result }
                        : product
                );
                setProductList(updatedProductList);
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <>
            {isLoading ?
                <Loading />
                :
                <><Helmet>
                    <title>Mẫu website giáo dục - trường học</title>
                    <meta name="description" content="Mẫu website giáo dục – trường học giành cho các đợn vị trường học từ mẫu web mầm non đến mẫu website đại học, các trang web trung tâm ngoại ngữ, trung tâm đào tạo kỹ năng, trung tâm lái xe, trung tâm tư vấn visa du học Hàn Quốc… Mỗi một đơn vị khác nhau lại có những quy cách cũng như phong cách về website khác nhau. Tại đây, DUC LAN WEBSITE xin hân hạnh cung cấp cho bạn các mẫu thiết kế website giáo dục – trường học mới nhất, hiện đại và phù hợp với những lĩnh vực mà các bạn đang giảng dạy." />
                    <meta name="keywords" content="mẫu website giáo dục, mẫu website trường học, thiết kế website trường học, website mầm non, website đại học, website trung tâm ngoại ngữ, website trung tâm đào tạo kỹ năng, website trung tâm lái xe, website tư vấn visa du học, thiết kế website giáo dục, website trường học hiện đại, website giáo dục chất lượng, website trường học giá rẻ, website trường học đẹp, thiết kế website trường học độc quyền, website giáo dục uy tín, website giáo dục theo yêu cầu, thiết kế website trường học chuyên nghiệp, website giáo dục chuẩn SEO, website giáo dục responsive" />
                </Helmet>
                    <HeaderHomepage />
                    <div className='category-landing-page'>
                        <div className='row banner-image'>
                            <div className='col-md-7 image'>
                                <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733751651/event_mni8jk.png' alt='' />
                            </div>
                            <div className='col-md-5 content'>
                                <h6 className='text'>
                                    <div className='animate-kassdev-right-1s'>Website</div>
                                    <div className='animate-kassdev-right-2s'>Giáo Dục</div>
                                </h6>
                                <div style={{ padding: '20px 0' }} className=' animate-kassdev-right-3s'><a href='tel:0379382992' className='tel' >Tư vấn thiết kế</a></div>
                            </div>
                        </div>
                        <div className='part-product'>
                            <div className='title animate-kassdev-right-2s'>Những sản phẩm bạn có thể <span style={{ color: '#50adff' }}>hứng thú</span></div>
                            <div className='row list-product'>
                                {
                                    product.map((item, index) => (
                                        <div onClick={() => handleViewTemplate(item.id)} className={index % 2 === 0 ? 'col-md-6 animate-kassdev-left-1s' : 'col-md-6 animate-kassdev-right-1s'}>
                                            <NavLink key={index} to={item.src} className='nav-link'>
                                                <div className="image-container">
                                                    <img
                                                        src={item.img}
                                                        alt={`Image ${index}`}
                                                    />
                                                </div>
                                                <p className='product-name'>{item.name}</p>

                                                <div className='product-price '>{item.price} <span style={{ fontSize: '13px', textTransform: 'uppercase', color: 'white' }}> - {item.view} lượt xem</span></div>
                                                <div className='btn-show'>
                                                    <div className="btn-type">Xem chi tiết</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='row introduce-landing-page'>
                            <div className='col-md-6 text'>
                                <h6 className='title animate-kassdev-left-1s'>Mẫu website giáo dục - trường học</h6>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Giáo dục là một trong những ngành đặc thù. Chính vì vậy các mẫu <span style={{ color: '#50adff' }}>website trường học – giáo dục</span> đều phải có sự khác biệt so với các lĩnh vực khác. Do đặc trưng của ngành nên các website phải thật nhã nhặn, nghiêm chỉnh mà phải không kém phần hiện đại.</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'><span style={{ color: '#50adff' }}>Mẫu website giáo dục – trường học</span> giành cho các đợn vị trường học từ <span style={{ color: '#50adff' }}>mẫu web mầm non</span> đến <span style={{ color: '#50adff' }}>mẫu website đại học</span>, các <span style={{ color: '#50adff' }}>trang web trung tâm ngoại ngữ</span>, trung tâm đào tạo kỹ năng, trung tâm lái xe, trung tâm tư vấn visa du học Hàn Quốc… Mỗi một đơn vị khác nhau lại có những quy cách cũng như phong cách về website khác nhau. Tại đây, <span style={{ color: '#50adff' }}>DUC LAN WEBSITE</span> xin hân hạnh cung cấp cho bạn các <span style={{ color: '#50adff' }}>mẫu thiết kế website giáo dục – trường học</span> mới nhất, hiện đại và phù hợp với những lĩnh vực mà các bạn đang giảng dạy.</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Sở hữu một mẫu website giới thiệu trường học giá rẻ và chất lượng là điều mọi khách hàng đều mong muốn. Hãy đến với Mona để sở hữu cho mình <span style={{ color: '#50adff' }}>mẫu website trường học độc quyền, rẻ, đẹp và chất lượng</span> để mang lại cho ngôi trường, trung tâm của bạn một sự uy tín và chất lượng đến từng chi tiết.</p>
                            </div>
                            <div className='col-md-6 image-intro animate-kassdev-right-2s'>
                                <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733756969/11111_rcy8u6.png' alt='' />
                            </div>
                        </div>
                        <div className='row type-website'>
                            <div className='title animate-kassdev-bottom-1s'>Danh Mục</div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Landing Page</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Studio - Wedding</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-3s'>
                                        <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Bất động sản</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>

                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Thương Mại Điện Tử Nhỏ</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Dashboard</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Giáo Dục</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Nhà Hàng - Quán Cà Phê</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FooterHomepage />
                </>
            }
        </>
    );
};

export default Index;
