import React, { useEffect, useState } from 'react';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import 'assets/List_Website/KLD2812/scss/about.scss'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import HeaderKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Header'
import FooterKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Footer'
const Index = () => {
    const [changePage, setChangePage] = useState(false)
    useEffect(() => {
        const sections = document.querySelectorAll(".animate-section");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate");
                    }
                });
            },
            { threshold: 0.3 }
        );

        sections.forEach((section) => observer.observe(section));
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.to(".images img", {
            scale: 1.3,
            scrollTrigger: {
                trigger: ".section-2-kld2812",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });

    }, []);
    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    return (
        <>
            <HeaderKLD2812 setChangePage={setChangePage} changePage={changePage} />
            <div className='about-kld2812' style={{ opacity: changePage ? 0 : 1, transition: 'all 0.4s' }}>
                <div className="section-1-kld2812" >
                    <video src="https://res.cloudinary.com/dolydpat4/video/upload/v1735723456/634d567105fc1005179ded4e_ABOUT-VIDEO-DS720_2_-transcode_xkqyzo.mp4" autoPlay loop muted playsInline></video>
                    <div className='introduce'>
                        <div className='title animate-section'>John & Diana</div>
                        <div className="sub-title animate-section">NGHỆ SĨ CHỤP ẢNH CƯỚI, TẬP TRUNG VÀO BẠN</div>

                    </div>
                </div>
                <div className="section-2-kld2812">
                    <span>
                        <div className="title animate-section">Một thập kỷ xây dựng nét thẩm mỹ đặc biệt</div>
                        <div className="text animate-section">Làm cho những điều quan trọng trở nên vĩnh cửu. </div>
                        <div className="text animate-section">Ghi lại những khoảnh khắc hạnh phúc nhất của bạn. </div>
                        <div className="text animate-section">Thể hiện con người thật của bạn.</div>
                    </span>
                    <div className="images animate-section">
                        <img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/657311243d7dc33e17b0681c_Danilo%20and%20Sharon%20photography.avif" alt="" />
                    </div>
                </div>
                <div className="section-3-kld2812">
                    <div className="title animate-section">Luôn tò mò về những điều chưa biết và luôn tìm kiếm điều tốt đẹp hơn</div>
                    <div className="text animate-section">John & Diana đã trở thành một thương hiệu được công nhận, nổi tiếng với cách tiếp cận thẩm mỹ rõ ràng và dịch vụ chụp ảnh theo yêu cầu. </div>
                    <div className="text animate-section">Chịu ảnh hưởng từ các tác phẩm thời trang và quảng cáo trước đó, John & Diana đã nuôi dưỡng một phong cách nhiếp ảnh quyến rũ các cô dâu hiện đại trên toàn thế giới. </div>
                    <div className="text animate-section">Phần lớn các tác phẩm ảnh cưới và chân dung được lấy cảm hứng từ chính địa điểm và chủ thể trong khi vẫn giữ được tính thẩm mỹ đặc trưng của tác giả. </div>
                    <div className="text animate-section">Làm việc trên phạm vi quốc tế, John & Diana đã chụp ảnh cưới và chân dung trên khắp thế giới, từ những con phố đông đúc của Hồng Kông đến Paris lịch sử. </div>
                    <div className="text animate-section">Studio có trụ sở tại Belgrade và được điều hành bởi vợ chồng Sharon và Danilo Vasic.</div>
                </div>
                <div className="section-4-kld2812">
                    <div className="text-box-left">
                        <div className="left animate-section">Như được giới thiệu trong</div>
                        <div className="right animate-section">Vogue ∙ Vogue Anh ∙ Elle ∙ Elle Decor ∙ VANITY FAIR ∙ The Lane ∙ Brides ∙ Junebug ∙ Cosmopolitan ∙ Tạp chí Rangefinder ∙ THE WED ∙ Daily Mail ∙ Huffington Post</div>
                    </div>
                    <div className="text-box-right">
                        <div className="left animate-section">Điểm đến trước đó</div>
                        <div className="right animate-section">Ý ∙ Pháp ∙ Hy Lạp ∙ TÂY BAN NHA ∙ Bồ Đào Nha ∙ Thụy Sĩ ∙ Đức ∙ Bỉ ∙ Hà Lan ∙ Áo ∙ Thụy Điển ∙ Phần Lan ∙ Croatia ∙ Montenegro ∙ Hồng Kông ∙ Ai Cập ∙ Cộng hòa Dominica ∙ NAM PHI ∙ Ma-rốc</div>
                    </div>
                    <div className="text-box-center">
                        <div className="left animate-section">Được trao tặng bởi</div>
                        <div className="right animate-section">
                            <div className="text animate-section">30 nhiếp ảnh gia chụp ảnh cưới hàng đầu Châu Âu - The Lane</div>
                            <div className="text animate-section">30 ngôi sao mới nổi của nhiếp ảnh cưới - Tạp chí Rangefinder, New York</div>
                            <div className="text animate-section">Nhiếp ảnh gia chụp ảnh cưới trẻ tuổi xuất sắc nhất Châu Âu - Giải thưởng Way Up North, Stockholm</div>
                        </div>
                    </div>

                </div>
                <div className="section-11-kld2812">
                    <div className="title animate-section">Dear John & Diana</div>
                    <div className="box-sent">
                        <div className=' animate-section text'>Tên tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Họ & Tên' />
                        <div className="text animate-section">và tôi đang lên kế hoạch</div>
                        <input type="text" className=' animate-section input-text' placeholder='Lễ cưới' />
                        <div className="text animate-section">trong</div>
                        <input type="text" className=' animate-section input-text' placeholder='Trung tâm' />
                        <div className="text animate-section">vào</div>
                        <input type="text" className=' animate-section input-text' placeholder='Ngày x tháng x năm xxxx' />
                        <div className="text animate-section">người lập kế hoạch đám cưới của tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Nguyễn Văn A' />
                        <div className="text animate-section">và tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='@Nguyễn Thị B' />
                        <div className="text animate-section">trên instagram</div>
                    </div>
                    <div className="btn-sent animate-section">Vui lòng chờ...</div>
                </div>
            </div>
            <FooterKLD2812 />
        </>
    );
}

export default Index;
