import React, { useState } from 'react';
import 'assets/Ref/scss/ref.scss'
import { useApp } from 'components/Homepage/context/AppContext';
const Index = () => {
    const { setRef } = useApp();
    const [section, setSection] = useState(true)
    const handleChangeRef = (e) => {
        setRef(e.target.value)
    }
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);
    const zaloLink = isIOS ? 'zalo://qr/p/b0vaft11czp1' : isAndroid ? 'https://zaloapp.com/qr/p/b0vaft11czp1' : 'zalo://conversation?phone=0379382992';
    return (
        <>
            <div class="background-dot">
                <div class="dot top-left"></div>
                <div class="dot top-right"></div>
                <div class="dot bottom-left"></div>
                <div class="dot bottom-right"></div>
            </div>
            <div className='section-ref-show'>
                {section ?
                    <div className="section-1">
                        <div className="title">Nhập mã mời của bạn</div>
                        <input type="text" onChange={handleChangeRef} placeholder='Nhập mã mời của bạn' />
                        <div className="text-sub" onClick={() => setSection(false)}>Bạn chưa có mã mời</div>
                    </div>
                    :
                    <div className="section-2">
                        <div className="title">Số điện thoại nhận mã</div>
                        <input type="text" placeholder='Nhập số điện thoại của bạn' />
                        <div className='btn-ref'>Xác nhận</div>
                        <a href={zaloLink} target='blank' className="text">Nhận mã mời qua zalo</a>
                        <div className="text-sub" onClick={() => setSection(true)}>Bạn đã có mã mời</div>
                    </div>
                }


            </div>
        </>

    );
}

export default Index;
