import Lenis from 'lenis'
import { useEffect, useState } from 'react';
import 'assets/List_Website/KLD2812/scss/homepage.scss';
import 'lenis/dist/lenis.css'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import HeaderKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Header'
import FooterKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Footer'
const Index = () => {
    const [changePage, setChangePage] = useState(false)
    useEffect(() => {
        const sections = document.querySelectorAll(".animate-section");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate");
                    }
                });
            },
            { threshold: 0.3 }
        );

        sections.forEach((section) => observer.observe(section));
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.to("body .homepage-kld2812", {
            backgroundColor: "#000",
            scrollTrigger: {
                trigger: ".section-6-kld2812",
                start: "top center",
                end: "center center",
                scrub: 1,
                onLeaveBack: () => {
                    gsap.to("body", { backgroundColor: "#f3f3f3", overwrite: "auto" });
                },
                onEnterBack: () => {
                    gsap.to("body", { backgroundColor: "#000", overwrite: "auto" });
                },
            },
        });
        gsap.to(".header-kld2812", {
            color: "#f3f3f3",
            scrollTrigger: {
                trigger: ".section-6-kld2812",
                start: "top center",
                end: "center center",
                scrub: true,
                onLeaveBack: () => {
                    gsap.to(".header-kld2812", { color: "#000", overwrite: "auto" });
                },
                onEnterBack: () => {
                    gsap.to(".header-kld2812", { color: "#f3f3f3", overwrite: "auto" });
                },
            },
        });
        const contents = gsap.utils.toArray('#horizontal .content');

        gsap.to(contents, {
            xPercent: -100 * (contents.length - 1),
            scrollTrigger: {
                trigger: "#horizontal",
                pin: true,
                scrub: 1,
                start: "top top",
                end: "+=3000",
            },
        });

        gsap.to(".img-left", {
            y: 300,
            scrollTrigger: {
                trigger: ".section-9-kld2812",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });


        gsap.to(".img-right", {
            y: -300,
            scrollTrigger: {
                trigger: ".section-9-kld2812",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });
        gsap.to("body .homepage-kld2812", {
            backgroundColor: "#f3f3f3",
            scrollTrigger: {
                trigger: ".section-11-kld2812",
                start: "top center",
                end: "center center",
                scrub: 1,
                onLeaveBack: () => {
                    gsap.to("body", { backgroundColor: "#000", overwrite: "auto" });
                },
                onEnterBack: () => {
                    gsap.to("body", { backgroundColor: "#f3f3f3", overwrite: "auto" });
                },
            },
        });
        gsap.to(".header-kld2812", {
            color: "#000",
            scrollTrigger: {
                trigger: ".section-11-kld2812",
                start: "top center",
                end: "top center",
                scrub: true,
                onLeaveBack: () => {
                    gsap.to(".header-kld2812", { color: "#f3f3f3", overwrite: "auto" });
                },
                onEnterBack: () => {
                    gsap.to(".header-kld2812", { color: "#000", overwrite: "auto" });
                },
            },
        });

    }, []);
    useEffect(() => {
        const lenis = new Lenis();

        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);

        return () => lenis.destroy();
    }, []);
    return (
        <>
            <style>
                {`
            body{
                background-color: rgb(243 243 243);
            }
            `}
            </style>
            <HeaderKLD2812 setChangePage={setChangePage} changePage={changePage} />
            <div className="homepage-kld2812" style={{ opacity: changePage ? 0 : 1, transition: 'all 0.4s' }}>
                <div className="section-1-kld2812">
                    <img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/646b3d733706b3d085194551_Danilo-and-Sharon-luxury-wedding-photography-studio-in-Europe.avif"></img>
                    <div className='introduce'>
                        <div className='title animate-section'>Quá trình biên tập</div>
                        <div className="sub-title animate-section">đám cưới
                        </div>
                        <div className='title animate-section'>Chụp ảnh</div>
                        <div className="text animate-section">CHO PHÉP NHỮNG KHOẢNH KHẮC ĐỘC ĐÁO CỦA BẠN ĐƯỢC GIÀU CÓ MỘT CÁCH ĐẸP MẮT</div>
                    </div>
                </div>
                <div className="section-2-kld2812">
                    <div className="section-2-1">
                        <div className="title animate-section">Họ nói về chúng tôi</div>
                        <div className="images">
                            <img className='col-md-4 animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6337636c56c764d149d52cd2_VOGUE%20LOGO.svg" alt="" />
                            <img className='col-md-4 animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6337636d09496da866a3f248_ELLE%20LOGO.svg" alt="" />
                            <img className='col-md-4 animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/638212fd42077f30eba4e7e5_lane-logo-normal-black.svg" alt="" />
                            <img className='col-md-4 animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/64307643756ab309a513c215_London%2BWedding%2BPhotographer%2B-%2BSikh%2B%26%2BDread.png" alt="" />
                        </div>
                        <div className="text animate-section">
                            "Có ai có thể duy trì tình yêu như John & Diana không?"
                            <div className="sub-text">TẠP CHÍ ELLE</div>
                        </div>

                    </div>
                </div>
                <div className="section-3-kld2812">
                    <div className="section-3-1">
                        <div className="title animate-section">Câu chuyện tuyệt vời là câu chuyện được kể hay</div>
                    </div>
                    <div className="section-3-2">
                        <div className="col-md-4 left">
                            <div className="text animate-section">NHƯNG KHÔNG CHỈ LÀ CHỤP NHỮNG BỨC ẢNH ĐẸP MÀ</div>
                            <div className="text animate-section">CÒN LÀ MANG ĐẾN CHO BẠN MỘT TRẢI NGHIỆM TOÀN DIỆN</div>
                        </div>
                        <div className="col-md-8 right"><div className="text animate-section">Liên hệ với chúng tôi</div></div>
                    </div>
                </div>
                <div className="section-4-kld2812">
                    <div className="col-4 e1st">
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/64299dd870b4df673a50064b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-225.avif" alt="" />
                    </div>
                    <div className="col-4 e2nd">
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e8ae151d20d36040efab_villa-cimbrone-ravello-amalfi-coast-wedding-danilo-and-sharon.avif" alt="" />
                    </div>
                    <div className="col-4 e1st">
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/659e69e8e484babb7dbafd3e_Danilo-and-Sharon-luxury-wedding-photography-76-p-800.avif" alt="" />
                    </div>
                </div>
                <div className="section-5-kld2812">
                    <div className="section-5-1">
                        <div className="title animate-section">Đám cưới thực sự của chúng tôi</div>
                    </div>
                    <div className="section-5-2">
                        <div className="col-md-8 right"><div className="text animate-section">Liên hệ với chúng tôi</div></div>
                        <div className="col-md-4 left">
                            <div className="text animate-section">ĐÂY LÀ QUAN ĐIỂM CỦA CHÚNG TÔI VỀ THỜI TRANG & ĐÁM CƯỚI</div>
                            <div className="text animate-section">KẾT HỢP CẢM HỨNG TỪ NHỮNG NGƯỜI VÀ ĐỊA ĐIỂM KHÁC NHAU</div>
                            <div className="text animate-section">VÀ MANG ĐẾN CHO MỖI CÂU CHUYỆN MỘT VẺ BIÊN TẬP</div>
                        </div>
                    </div>
                </div>
                <div className="section-6-kld2812">
                    <div id="horizontal">
                        <div className="content">
                            <div className="section-6-1">
                                <img className='col-md-4 left animate-section' src="https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/6756c2db1c80434774be764c_villa-pizzo-lake-como-extraordinary-wedding-23.avif" alt="" />
                                <div className="col-md-8 right">
                                    <div className="title animate-section">Một đám cưới đích thực vượt thời gian tại Villa Pizzo, Hồ Como</div>
                                    <div className="btn-right animate-section">Khám phá thêm</div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="section-6-1">
                                <img className='col-md-4 left' src="https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/674d8fad4ebb912d300a6d16_J-and-N-wedding-126-2.avif" alt="" />
                                <div className="col-md-8 right">
                                    <div className="title">Đám cưới sang trọng theo phong cách cổ điển tại Cung điện Badrutt của St. Moritz</div>
                                    <div className="btn-right">Khám phá thêm</div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="section-6-1">
                                <img className='col-md-4 left' src="https://cdn.prod.website-files.com/631c8aab743ea9161e45846d/675196028107fac284ee9f0e_villa-balbiano-wedding-lake-como-25.avif" alt="" />
                                <div className="col-md-8 right">
                                    <div className="title">Ký ức bên hồ tại Villa Balbiano, Hồ Como</div>
                                    <div className="btn-right">Khám phá thêm</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-7-kld2812">
                    <div className="section-7-1">
                        <div className="title animate-section">Sâu, di chuyển, độc nhất</div>
                    </div>
                    <div className="section-7-2">
                        <div className="col-md-8 right animate-section"><div className="text animate-section">Liên hệ với chúng tôi</div></div>
                        <div className="col-md-4 left">
                            <div className="text animate-section">TÁC PHẨM ĐƯỢC CHỌN LỌC CỦA CHÚNG TÔI LÀM NGUỒN CẢM HỨNG CỦA BẠN</div>
                        </div>
                    </div>
                </div>
                <div className="section-8-kld2812">
                    <div className="col-3">
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                    </div>
                    <div className="col-3 col-3-2" >
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                    </div>
                    <div className="col-3 col-3-3" >
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/66792b277598879fd54b5391_BL-wedding-60.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c548fc7bd0c483e27b_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-34.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3c6cdc29e646ce5b5e8_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-265.avif" alt="" />
                    </div>
                    <div className="col-3">
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e9621cd6e33c741a3f99_villa-balbiano-wedding-lake-como-46.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6756e98cd901f7159d7a16fd_villa-balbiano-wedding-lake-como-36.avif" alt="" />
                        <img className=' animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6454d3ba3fef365bbf9e3462_Danilo-and-Sharon-modern-luxury-destination-wedding-photography-studio-105.avif" alt="" />
                    </div>
                </div>
                <div className="section-9-kld2812">
                    <div className="title animate-section">Khám phá danh mục đầu tư của chúng tôi</div>
                    <img className='img-left animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6573123eaddd419a6d82b153_Danilo%20and%20Sharon%20about%20image%203-p-800.avif" alt="" />
                    <div className='box-text'>
                        <div className='sub-title animate-section'>Lấy cảm hứng từ những người chịu ảnh hưởng của thời trang</div>
                        <div className="text animate-section">MỘT ĐỘI NHIẾP ẢNH GIA VỚI HƯƠNG VỊ TOÀN CẦU VÀ GIA VỊ CHÂU ÂU.</div>
                        <div className="text animate-section">CHỈ QUAN TÂM ĐẾN VIỆC LÀM CHO ĐÁM CƯỚI CỦA BẠN TRỞ NÊN KHÔNG THỂ QUÊN</div>
                    </div>
                    <img className='img-right animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6573124d1f93b7288373dbbf_Danilo%20and%20Sharon%20about%20image-p-800.avif" alt="" />
                </div>
                <div className="section-10-kld2812">
                    <div className="content">
                        <div className="section-10-1">
                            <img className='col-md-4 left animate-section' src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6573337e6e11d649591566e9_Danilo%20and%20Sharon%20about%2000.avif" alt="" />
                            <div className="col-md-8 right">
                                <div className="title animate-section">Mười năm hoàn thiện chụp ảnh cưới trên toàn cầu và heieur được một số ngày có thể thay đổi cuộc sống của bạn thế nào.</div>
                                <div className="btn-right animate-section">Về chúng tôi</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-11-kld2812">
                    <div className="title animate-section">Dear John & Diana</div>
                    <div className="box-sent">
                        <div className=' animate-section text'>Tên tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Họ & Tên' />
                        <div className="text animate-section">và tôi đang lên kế hoạch</div>
                        <input type="text" className=' animate-section input-text' placeholder='Lễ cưới' />
                        <div className="text animate-section">trong</div>
                        <input type="text" className=' animate-section input-text' placeholder='Trung tâm' />
                        <div className="text animate-section">vào</div>
                        <input type="text" className=' animate-section input-text' placeholder='Ngày x tháng x năm xxxx' />
                        <div className="text animate-section">người lập kế hoạch đám cưới của tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Nguyễn Văn A' />
                        <div className="text animate-section">và tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='@Nguyễn Thị B' />
                        <div className="text animate-section">trên instagram</div>
                    </div>
                    <div className="btn-sent animate-section">Vui lòng chờ...</div>
                </div>
            </div>
            <FooterKLD2812 />
        </>
    );
};

export default Index;
