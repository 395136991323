import React, { useState, useEffect, useRef } from 'react';
import 'assets/Homepage/scss/Landing_page.scss';
import { NavLink, useLocation } from 'react-router-dom';
import Loading from 'components/Homepage/About/Loading';
import HeaderHomepage from 'components/Homepage/Header';
import FooterHomepage from 'components/Homepage/Footer';
import { Helmet } from 'react-helmet';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import {
    updateViewTemplate
} from 'Apis'
import { useApp } from 'components/Homepage/context/AppContext';
const Index = () => {
    const { dataIp, fetchPostIP, productList, setProductList } = useApp();
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const path = location.pathname
    const pageCategory = path.split("/").pop()

    const [product, setProduct] = useState([
        {
            id: 'KLD2812',
            img: 'https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/wedding_vqeipg.png',
            src1: 'https://kassdev.com/website/template/KLD2812',
            src: '/template/KLD2812',
            name: 'Mẫu website studio - wedding ( lễ cưới ) - KLD2812',
            title: 'John & Diana',
            view: 0,
            time: '4 - 7',
            price: 'Giá liên hệ',
            type: 'Cá nhân & Tổ chức',
            category: 'studio-chup-anh-cuoi'
        }
    ]);

    useEffect(() => {
        if (dataIp) {
            fetchPostIP({ ...dataIp, urlVisit: '/category/studio-chup-anh-cuoi' })
                .then(() => {
                    console.log('sent')
                })
        }
        const foundProducts = productList.filter(product => product.category === pageCategory);
        setProduct(foundProducts);
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, [dataIp]);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-top-1s",
                ".animate-kassdev-right-1s",
                ".animate-kassdev-left-1s",
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-top-2s",
                ".animate-kassdev-right-2s",
                ".animate-kassdev-left-2s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-top-3s",
                ".animate-kassdev-right-3s",
                ".animate-kassdev-left-3s",
                ".animate-kassdev-bottom-3s",
                ".animate-kassdev-top-4s",
                ".animate-kassdev-right-4s",
                ".animate-kassdev-left-4s",
                ".animate-kassdev-bottom-4s",
                ".animate-kassdev-top-5s",
                ".animate-kassdev-right-5s",
                ".animate-kassdev-left-5s",
                ".animate-kassdev-bottom-5s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);

    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    const handleViewTemplate = (id) => {
        updateViewTemplate(id)
            .then((result) => {
                // console.log(result)
                const updatedProductList = productList.map((product) =>
                    product.id === id
                        ? { ...product, ...result }
                        : product
                );
                setProductList(updatedProductList);
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <>
            {isLoading ?
                <Loading />
                :
                <><Helmet>
                    <title>Mẫu website studio & wedding</title>
                    <meta name="description" content="Mẫu website tin tức là mẫu website được thiết kế nhằm đăng tải những tin tức về những mảng thông tin nhất định như: tin tức kinh tế, công nghệ, máy tính, tin tức về lập trình, phần mềm, tin tức về mẹ và bé,… Những ai có mong muốn sở hữu một website về tin tức để có thể phục vụ cho công việc một cách tốt nhất thì hãy nhanh tay liên lạc với DUC LAN WEBSITE để có thể nhận được sự tư vấn cụ thể và trực tiếp nhất vì chính những lời tư vấn từ nhân viên của chúng tôi sẽ giúp cho các bạn có thêm nhiều ý tưởng cho một website tin tức giá rẻ của mình nhé." />
                    <meta name="keywords" content="mẫu website blog, thiết kế website blog, website tin tức, mẫu website tin tức, thiết kế website tin tức, tin tức kinh tế, tin tức công nghệ, tin tức lập trình, website tin tức giá rẻ, website mẹ và bé, thiết kế blog chuẩn SEO, blog tin tức chuyên nghiệp, thiết kế website responsive, website blog đa nền tảng, thiết kế blog chuẩn di động, website blog tại DUC LAN, thiết kế website tại DUC LAN, dịch vụ thiết kế website blog, thiết kế blog SEO tối ưu, tạo website tin tức, website blog giá rẻ" />
                </Helmet>
                    <HeaderHomepage />
                    <div className='category-landing-page'>
                        <div className='row banner-image'>
                            <div className='col-md-7 image animate-kassdev-left-2s'>
                                <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733751651/portfolio-blog_pgjfct.png' alt='' />
                            </div>
                            <div className='col-md-5 content' style={{ alignItems: 'center' }} >
                                <h6 className='text'>
                                    <div className='animate-kassdev-right-1s' style={{ textAlign: 'center' }}>Studio  - Wedding</div>
                                    <div className='animate-kassdev-right-2s' style={{ textAlign: 'end' }}></div>
                                </h6>
                                <div style={{ padding: '20px 0' }} className=' animate-kassdev-right-3s'><a href='tel:0379382992' className='tel' >Tư vấn thiết kế</a></div>
                            </div>
                        </div>
                        <div className='part-product'>
                            <div className='title animate-kassdev-right-2s'>Những sản phẩm bạn có thể <span style={{ color: '#50adff' }}>hứng thú</span></div>
                            <div className='row list-product'>
                                {
                                    product.map((item, index) => (
                                        <div onClick={() => handleViewTemplate(item.id)} className={index % 2 === 0 ? 'col-md-6 animate-kassdev-left-1s' : 'col-md-6 animate-kassdev-right-1s'}>
                                            <NavLink key={index} to={item.src} className='nav-link'>
                                                <div className="image-container">
                                                    <img
                                                        src={item.img}
                                                        alt={`Image ${index}`}
                                                    />
                                                </div>
                                                <p className='product-name'>{item.name}</p>

                                                <div className='product-price '>{item.price} <span style={{ fontSize: '13px', textTransform: 'uppercase', color: 'white' }}> - {item.view} lượt xem</span></div>
                                                <div className='btn-show'>
                                                    <div className="btn-type">Xem chi tiết</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='row introduce-landing-page'>
                            <div className='col-md-6 text'>
                                <h6 className='title animate-kassdev-left-1s'>Mẫu website studio & wedding</h6>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'><span style={{ color: '#50adff' }}>Mẫu website tin tức</span> là mẫu website được thiết kế nhằm đăng tải những tin tức về những mảng thông tin nhất định như: tin tức kinh tế, công nghệ, máy tính, tin tức về lập trình, phần mềm, tin tức về mẹ và bé,… Những ai có mong muốn sở hữu một website về tin tức để có thể phục vụ cho công việc một cách tốt nhất thì hãy nhanh tay liên lạc với DUC LAN WEBSITE để có thể nhận được sự tư vấn cụ thể và trực tiếp nhất vì chính những lời tư vấn từ nhân viên của chúng tôi sẽ giúp cho các bạn có thêm nhiều ý tưởng cho một website tin tức giá rẻ của mình nhé.</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Tuy là một <span style={{ color: '#50adff' }}>website tin tức giá rẻ</span>, nhưng website tại <span style={{ color: '#50adff' }}>DUC LAN WEBSITE</span> luôn được chăm chút về mặt chất lượng, <span style={{ color: '#50adff' }}>thiết kế website chuẩn SEO</span> và hướng tới người dùng, <span style={{ color: '#50adff' }}>thiết kế website responsive – chuẩn di dộng – đa nền tảng</span> giúp cho website có thể hoạt động tối ưu trên các phương tiện di động như điện thoại di động và máy tính bảng.</p>
                            </div>
                            <div className='col-md-6 image-intro animate-kassdev-right-2s'>
                                <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733756969/11111_rcy8u6.png' alt='' />
                            </div>
                        </div>
                        <div className='row type-website'>
                            <div className='title animate-kassdev-bottom-1s'>Danh Mục</div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Landing Page</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Studio - Wedding</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-3s'>
                                        <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Bất động sản</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>

                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Thương Mại Điện Tử Nhỏ</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Dashboard</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Giáo Dục</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Nhà Hàng - Quán Cà Phê</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FooterHomepage />
                </>
            }
        </>
    );
};

export default Index;
