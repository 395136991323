import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const ChartComponent = ({ data }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const root = am5.Root.new(chartRef.current);
        root.setThemes([am5themes_Animated.new(root)]);
        root._logo.dispose();
        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "none",
                wheelY: "none",
                paddingLeft: 0,
                highQuality: true
            })
        );

        chart.zoomOutButton.set("forceHidden", true);

        const xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 30,
            minorGridEnabled: true,
        });
        xRenderer.labels.template.setAll({
            fontSize:14,
            rotation: 0,
            paddingTop: 15,
        });
        xRenderer.grid.template.set("visible", false);

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0.3,
                categoryField: "location",
                renderer: xRenderer,
            })
        );

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                min: 0,
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );

        const series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "location",
            })
        );

        series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            strokeOpacity: 0,
        });

        series.columns.template.adapters.add("fill", (fill, target) => {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", (stroke, target) => {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        xAxis.data.setAll(data); // Gán dữ liệu trục X
        series.data.setAll(data); // Gán dữ liệu series
        series.columns.template.setAll({
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            strokeWidth: 0, // Loại bỏ đường viền để cột mịn hơn
        });
        series.appear(1000);
        chart.appear(1000, 100);
        series.bullets.push(() => {
            return am5.Bullet.new(root, {
                locationY: 1,
                sprite: am5.Label.new(root, {
                    text: "{valueYWorking.formatNumber('#.')}",
                    fill: am5.color("#000"),
                    centerY: am5.p50,
                    centerX: am5.p50,
                    fontSize: "12px",
                    paddingBottom:22,
                    populateText: true,
                }),
            });
        });
        
        return () => {
            root.dispose();
        };
    }, [data]); // Lắng nghe thay đổi của dữ liệu

    return <div id="chartAccess" ref={chartRef} ></div>;
};

export default ChartComponent;
