import React, { useState, useEffect, useRef } from 'react';
import 'assets/Homepage/scss/Landing_page.scss';
import { NavLink, useLocation } from 'react-router-dom';
import Loading from 'components/Homepage/About/Loading';
import HeaderHomepage from 'components/Homepage/Header';
import FooterHomepage from 'components/Homepage/Footer';
import { Helmet } from 'react-helmet';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import {
    updateViewTemplate
} from 'Apis'
import { useApp } from 'components/Homepage/context/AppContext';
const Index = () => {
    const { dataIp, fetchPostIP, productList, setProductList } = useApp();
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const path = location.pathname
    const pageCategory = path.split("/").pop()
    const [product, setProduct] = useState([
        {
            id: 'KLD0002',
            img: 'https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/db_oakvr6.png',
            src1: 'https://ktech-dashboard.onrender.com/v2/',
            src: '/template/KLD0002',
            name: 'mẫu dashboard - KLD0002',
            title: 'Ktech dashboard',
            view: 0,
            time: '4 - 7',
            price: 'Giá liên hệ',
            type: 'Cá nhân & Tổ chức',
            category: 'website-dashboard'
        }
    ]);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-top-1s",
                ".animate-kassdev-right-1s",
                ".animate-kassdev-left-1s",
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-top-2s",
                ".animate-kassdev-right-2s",
                ".animate-kassdev-left-2s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-top-3s",
                ".animate-kassdev-right-3s",
                ".animate-kassdev-left-3s",
                ".animate-kassdev-bottom-3s",
                ".animate-kassdev-top-4s",
                ".animate-kassdev-right-4s",
                ".animate-kassdev-left-4s",
                ".animate-kassdev-bottom-4s",
                ".animate-kassdev-top-5s",
                ".animate-kassdev-right-5s",
                ".animate-kassdev-left-5s",
                ".animate-kassdev-bottom-5s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);

    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);

    useEffect(() => {
        if (dataIp) {
            fetchPostIP({...dataIp, urlVisit:'/category/website-dashboard'})
            .then(() => {
                console.log('sent')
            })
        }
        const foundProducts = productList.filter(product => product.category === pageCategory);
        setProduct(foundProducts);
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, [dataIp]);
    const handleViewTemplate = (id) => {
        updateViewTemplate(id)
            .then((result) => {
                // console.log(result)
                const updatedProductList = productList.map((product) =>
                    product.id === id
                        ? { ...product, ...result }
                        : product
                );
                setProductList(updatedProductList);
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <>
            {isLoading ?
                <Loading />
                :
                <><Helmet>
                <title>Mẫu website dashboard - Mẫu website admin - - Mẫu website quản lý</title>
                <meta name="description" content="Mẫu website dashboard là Khi điều hành hoặc quản lý một dự án lớn, Dashboard này là một công cụ hữu ích để theo dõi tiến trình dự án và có thể chia sẻ được với nhóm của bạn và các bên liên quan khác. Chúng cung cấp một cái nhìn đầy đủ về tình hình dự án, thông tin chi tiết và dữ liệu chính. Chúng hữu ích cho tiếp thị, tài chính, quảng cáo, nguồn nhân lực và cả các nhóm kinh doanh khác." />
                <meta name="keywords" content="mẫu website dashboard, thiết kế website dashboard, website quản lý dự án, dashboard theo dõi hiệu suất, website tổng quan dự án, dashboard theo dõi trang web, thiết kế dashboard doanh nghiệp, website dashboard bán hàng, dashboard doanh thu, dashboard theo dõi người dùng, dashboard lưu lượng truy cập, dashboard cho doanh nghiệp, thiết kế website dashboard cho doanh nghiệp, dashboard tiếp thị, dashboard tài chính, dashboard quảng cáo, dashboard nguồn nhân lực, dashboard tổ chức kinh doanh, website theo dõi hoạt động kinh doanh, dashboard thương mại điện tử, website dashboard báo cáo doanh thu, thiết kế website dashboard theo yêu cầu"/>
            </Helmet>
                    <HeaderHomepage />
                    <div className='category-landing-page'>
                        <div className='row banner-image'>
                            <div className='col-md-7 image animate-kassdev-left-2s'>
                            <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733748355/qqq_ikjfpa.png' alt='' />
                            </div>
                            <div className='col-md-5 content'>
                                <h6 className='text'>
                                    <div className='animate-kassdev-right-1s'>Website</div>
                                    <div className='animate-kassdev-right-2s'>Dashboard</div>
                                </h6>
                                <div style={{ padding: '20px 0' }} className=' animate-kassdev-right-3s'><a href='tel:0379382992' className='tel' >Tư vấn thiết kế</a></div>
                            </div>
                        </div>
                        
                        <div className='part-product'>
                            <div className='sub-title animate-kassdev-right-1s'>Danh sách sản phẩm</div>
                            <div className='title animate-kassdev-right-2s'>Những sản phẩm bạn có thể hứng thú</div>
                            <div className='row list-product'>
                                {
                                    product.map((item, index) => (
                                        <div onClick={() => handleViewTemplate(item.id)} className={index % 2 === 0 ? 'col-md-6 animate-kassdev-left-1s' : 'col-md-6 animate-kassdev-right-1s'}>
                                            <NavLink key={index} to={item.src} className='nav-link'>
                                                <div className="image-container">
                                                    <img
                                                        src={item.img}
                                                        alt={`Image ${index}`}
                                                    />
                                                </div>
                                                <p className='product-name'>{item.name}</p>

                                                <div className='product-price '>{item.price} <span style={{ fontSize: '13px', textTransform: 'uppercase', color: 'white' }}> - {item.view} lượt xem</span></div>
                                                <div className='btn-show'>
                                                    <div className="btn-type">Xem chi tiết</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='row introduce-landing-page'>
                            <div className='col-md-6 text'>
                                <h6 className='title animate-kassdev-left-1s'>Mẫu website dashboard</h6>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Mẫu website dashboard là Khi điều hành hoặc quản lý một dự án lớn, Dashboard này là một công cụ hữu ích để theo dõi tiến trình dự án và có thể chia sẻ được với nhóm của bạn và các bên liên quan khác. Chúng cung cấp một cái nhìn đầy đủ về tình hình dự án, thông tin chi tiết và dữ liệu chính. Chúng hữu ích cho tiếp thị, tài chính, quảng cáo, nguồn nhân lực và cả các nhóm kinh doanh khác.</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Khi theo dõi hiệu suất trang web, việc tạo trang tổng quan trang web rất hữu ích. Website Dashboard theo dõi các dữ liệu quan trọng như lưu lượng truy cập tổng thể, tổng số người dùng, người dùng đang hoạt động, hoạt động thương mại điện tử, bán hàng và doanh thu.Cho dù doanh nghiệp duy trì một trang web đơn giản hay phức tạp, bảng điều khiển này đều có thể cung cấp một cái nhìn tích hợp, rõ ràng về các chỉ số.</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Đây là Dashboard tổ chức kinh doanh sử dụng rất phổ biến: điều hành và cung cấp tổng thể về hoạt động theo ngày của doanh nghiệp. Vì vậy nếu bạn cần xây dựng riêng thì đừng ngần ngại liên hệ hoặc để lại thông tin – chuyên viên của chúng tôi sẽ hỗ trợ tư vấn ngay với bạn</p>
                            </div>
                            <div className='col-md-6 image-intro animate-kassdev-right-2s'>
                            <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733756969/11111_rcy8u6.png' alt='' />
                            </div>
                        </div>
                        <div className='row type-website'>
                            <div className='title animate-kassdev-bottom-1s'>Danh Mục</div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Landing Page</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Studio - Wedding</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-3s'>
                                        <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Bất động sản</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>

                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Thương Mại Điện Tử Nhỏ</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Dashboard</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Giáo Dục</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Nhà Hàng - Quán Cà Phê</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FooterHomepage />
                </>
            }
        </>
    );
};

export default Index;
