import React, { useState, useEffect } from 'react';
import 'assets/List_Website/KLD2112/scss/header.scss';
import 'assets/List_Website/KLD2112/scss/loading.scss';
import Logo from 'assets/List_Website/KLD2112/images/logo-property.svg'
import { NavLink, useNavigate } from 'react-router-dom'
const Index = (props) => {
    const { changePage, setChangePage } = props
    const navigate = useNavigate();
    const [isMenu, setIsMenu] = useState(false)
    const [isDropdownMenu1, setIsDropdownMenu1] = useState(false)
    const [isDropdownMenu2, setIsDropdownMenu2] = useState(false)
    const [isDropdownMenu3, setIsDropdownMenu3] = useState(false)
    const [isDropdownMenu4, setIsDropdownMenu4] = useState(false)
    const [ isScrolled, setScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 300;
            setScrolled(isScrolled);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const resetHeaderNav = () => {
        setIsDropdownMenu1(false)
        setIsDropdownMenu2(false)
        setIsDropdownMenu3(false)
        setIsDropdownMenu4(false)
        setIsMenu(false)
    }
    const handleClick = (url) => {
        setChangePage(true)
        window.location.hash = "#top";
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
            document.body.style.overflow = 'auto';
            navigate(url);
            setChangePage(false)
        }, 900);
    };
    return (
        <>
            {changePage ? <div className="loader1-kld2112"></div> : <div className="loader-kld2112"></div>}
            <div className={isScrolled ? 'header-kld2112 scroll' : 'header-kld2112'}>
                <div className='list-nav'>
                    <a onClick={() => handleClick('/website/template/KLD2112')} className='logo'>
                        <img src={Logo} alt='Logo' />
                    </a>
                    <div className='nav'>
                        <div class="dropdown">
                            <button class="dropbtn">Tìm kiếm nhà <i className='fa fa-chevron-right'></i></button>
                            <div class="dropdown-content">
                                <a onClick={() => handleClick('/website/template/KLD2112/danh-sach-nha')} >Phía Đông</a>
                                <a onClick={() => handleClick('/website/template/KLD2112/danh-sach-nha')} >Phía Tây</a>
                                <a onClick={() => handleClick('/website/template/KLD2112/danh-sach-nha')} >Quảng Trường</a>
                                <a onClick={() => handleClick('/website/template/KLD2112/danh-sach-nha')} >Trung Tâm</a>
                            </div>
                        </div>
                        <div class="dropdown">
                            <button class="dropbtn">Người bán <i className='fa fa-chevron-right'></i></button>
                            <div class="dropdown-content">
                                <a onClick={() => handleClick('/website/template/KLD2112/quy-trinh-ban-nha')} >Quá trình bán nhà</a>
                                <a onClick={() => handleClick('/website/template/KLD2112/giao-dich-da-ban')} >Giao dịch đã bán</a>
                            </div>
                        </div>
                        <div class="dropdown">
                            <button class="dropbtn">Người mua <i className='fa fa-chevron-right'></i></button>
                            <div class="dropdown-content">
                                <a onClick={() => handleClick('/website/template/KLD2112/quy-trinh-mua-nha')} >Quy trình mua nhà</a>
                                <a onClick={() => handleClick('/website/template/KLD2112/danh-sach-hien-tai')} >Danh sách hiện tại</a>
                            </div>
                        </div>
                        <div class="dropdown">
                            <button class="dropbtn">Thị trường <i className='fa fa-chevron-right'></i></button>
                            <div class="dropdown-content">
                                <a onClick={() => handleClick('/website/template/KLD2112/bao-cao-thi-truong')} >Báo cáo thị trường</a>
                                <a onClick={() => handleClick('/website/template/KLD2112/xu-huong')} >Xu hướng thị trường</a>
                                <a onClick={() => handleClick('/website/template/KLD2112/tin-tuc')} >Tin tức</a>
                            </div>
                        </div>
                        <a onClick={() => handleClick('/website/template/KLD2112/ve-toi')} className='nav-item'>Về tôi</a>
                    </div>
                    <NavLink to={''} className='logo'><i className='fa fa-user' style={{ color: 'white', fontSize: 24, cursor: 'pointer' }}></i></NavLink>

                </div>
                <div className='mobile-list-nav'>
                    <img src={Logo} onClick={() => handleClick('/website/template/KLD2112')} alt='Logo' />
                    <div className='button-list-nav'>
                        <NavLink to={'/website/template/KLD2112/account'} className='logo'><i className='fa fa-user' style={{ color: 'white', fontSize: 24, cursor: 'pointer' }}></i></NavLink>
                        <div style={{ display: 'flex', gap: 15, alignItems: 'center', paddingRight: 15 }}>

                            <div id="checkbox2" className={isMenu ? 'checked' : ''}></div>
                            <label class="toggle toggle2" for="checkbox2" onClick={() => { setIsMenu(!isMenu) }}>
                                <div id="bar4" class="bars"></div>
                                <div id="bar5" class="bars"></div>
                                <div id="bar6" class="bars"></div>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`nav-mobile-kld2112 ${isMenu ? 'menu-show' : 'menu-hide'}`}>
                <div className='list-nav'>

                    <div
                        onClick={() => setIsDropdownMenu1(!isDropdownMenu1)}
                        className='nav-item'
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                            <i className='fa fa-home'></i>Tìm kiếm nhà
                        </span>
                        <i className={isDropdownMenu1 ? 'fa fa-chevron-right active-fa-chevron-right' : 'fa fa-chevron-right deactivate-fa-chevron-right'}></i>
                    </div>
                    <ul className={`sub-menu-kld2112 ${isDropdownMenu1 ? 'open' : ''}`}>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/danh-sach-nha'); resetHeaderNav() }}><div>Phía đông</div> <span><i className='fa'></i></span></a></li>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/danh-sach-nha'); resetHeaderNav() }}><div>Phía tây</div> <span><i className='fa'></i></span></a></li>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/danh-sach-nha'); resetHeaderNav() }}><div>Quảng trường</div> <span><i className='fa'></i></span></a></li>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/danh-sach-nha'); resetHeaderNav() }}><div>Trung tâm</div> <span><i className='fa'></i></span></a></li>
                    </ul>
                    <div
                        onClick={() => setIsDropdownMenu2(!isDropdownMenu2)}
                        className='nav-item'
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                            <i className='fa fa-book-open'></i>Người bán
                        </span>
                        <i className={isDropdownMenu2 ? 'fa fa-chevron-right active-fa-chevron-right' : 'fa fa-chevron-right deactivate-fa-chevron-right'}></i>
                    </div>
                    <ul className={`sub-menu-kld2112 ${isDropdownMenu2 ? 'open' : ''}`}>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/quy-trinh-ban-nha'); resetHeaderNav() }}><div>Quá trình bán nhà</div> <span><i className='fa'></i></span></a></li>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/giao-dich-da-ban'); resetHeaderNav() }}><div>Giao dịch đã bán</div> <span><i className='fa'></i></span></a></li>
                    </ul>
                    <div
                        onClick={() => setIsDropdownMenu3(!isDropdownMenu3)}
                        className='nav-item'
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                            <i className='fa fa-book-open'></i>Người mua
                        </span>
                        <i className={isDropdownMenu3 ? 'fa fa-chevron-right active-fa-chevron-right' : 'fa fa-chevron-right deactivate-fa-chevron-right'}></i>
                    </div>
                    <ul className={`sub-menu-kld2112 ${isDropdownMenu3 ? 'open' : ''}`}>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/quy-trinh-ban-nha'); resetHeaderNav() }}><div>Quá trình mua nhà</div> <span><i className='fa'></i></span></a></li>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/danh-sach-hien-tai'); resetHeaderNav() }}><div>Danh sách hiện tại</div> <span><i className='fa'></i></span></a></li>
                    </ul>
                    <div
                        onClick={() => setIsDropdownMenu4(!isDropdownMenu4)}
                        className='nav-item'
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                            <i className='fa fa-book-open'></i>Thị trường
                        </span>
                        <i className={isDropdownMenu4 ? 'fa fa-chevron-right active-fa-chevron-right' : 'fa fa-chevron-right deactivate-fa-chevron-right'}></i>
                    </div>
                    <ul className={`sub-menu-kld2112 ${isDropdownMenu4 ? 'open' : ''}`}>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/bao-cao-thi-truong'); resetHeaderNav() }}><div>Báo cáo thị trường</div> <span><i className='fa'></i></span></a></li>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/xu-huong'); resetHeaderNav() }}><div>Xu hướng thị trường</div> <span><i className='fa'></i></span></a></li>
                        <li><a onClick={() => { handleClick('/website/template/KLD2112/tin-tuc'); resetHeaderNav() }}><div>Tin tức</div> <span><i className='fa'></i></span></a></li>
                    </ul>
                    <a onClick={() => { handleClick('/website/template/KLD2112/ve-toi'); resetHeaderNav() }} className='nav-item'><i className='fa fa-message'></i>Về tôi</a>
                </div>
            </div>

        </>
    );
}

export default Index;
