import axios from 'axios'
import { API_ROOT } from 'utils'

export const fetchTemplate = async () => {
    const req = await axios.get(`${API_ROOT}/v1/template`)
    return req.data
}

export const updateViewTemplate = async (id) => {
    const req = await axios.put(`${API_ROOT}/v1/template/${id}`)
    return req.data
}

export const fetchAnalysisXeGhep = async (data) => {
    const req = await axios.get(`${API_ROOT}/v1/ipXeGhep/analysis`, {
        params: {
            ...data
        }
    })
    return req.data
}