import React, { useEffect, useState } from 'react';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import 'assets/List_Website/KLD2812/scss/photo.scss'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import HeaderKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Header'
import FooterKLD2812 from 'components/List_Website/Studio_Wedding/KLD2812/Footer'
const Index = () => {
    const [changePage, setChangePage] = useState(false)
    useEffect(() => {
        const sections = document.querySelectorAll(".animate-section");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("animate");
                    }
                });
            },
            { threshold: 0.3 }
        );

        sections.forEach((section) => observer.observe(section));
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.to(".text-box-left", {
            y: 200,
            scrollTrigger: {
                trigger: ".section-3-kld2812",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });

        gsap.to(".text-box-center", {
            y: -200,
            scrollTrigger: {
                trigger: ".section-3-kld2812",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });
        gsap.to(".text-box-right", {
            y: -400,
            scrollTrigger: {
                trigger: ".section-3-kld2812",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });
        gsap.to(".section-5-1 .right img", {
            scale: 0.8,
            scrollTrigger: {
                trigger: ".section-5-1",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });
        gsap.to(".section-5-2 .left img", {
            scale: 0.8,
            scrollTrigger: {
                trigger: ".section-5-2",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });
        gsap.to(".section-5-3 .right img", {
            scale: 0.8,
            scrollTrigger: {
                trigger: ".section-5-3",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });
        gsap.to(".section-7-1 .right img", {
            scale: 0.8,
            scrollTrigger: {
                trigger: ".section-7-1",
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });

    }, [changePage]);
    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    return (
        <>
            <HeaderKLD2812 setChangePage={setChangePage} changePage={changePage} />
            <div className='photo-book-kld2812'style={{ opacity: changePage ? 0 : 1, transition: 'all 0.4s' }}>
                <div className="section-1-kld2812" >
                    <video src="https://res.cloudinary.com/dolydpat4/video/upload/v1735745770/6429dcfcd5a0814705513100_website_reflectionsmov-720p-transcode_hkdcny.mp4" autoPlay loop muted playsInline></video>
                    <div className='introduce'>
                        <div className='title animate-section'>Sự phản chiếu</div>
                        <div className="sub-title animate-section">Một cuốn sách về bàn cà phêvề bạn</div>
                    </div>
                </div>
                <div className="section-2-kld2812">
                    <div className="title animate-section">Một cuốn sách ảnh cưới có thể đặt trên bàn cà phê cạnh Tom Ford 002 của bạn</div>
                    <div className="btn-text animate-section">NHẬN THÊM THÔNG TIN</div>

                </div>
                <div className="section-3-kld2812">
                    <div className="text-box-left">
                        <div className="left animate-section">câu chuyện của bạn. Bên trong một hộp san hô. Đóng trong bìa gương mờ.</div>
                        <div className="right animate-section"><img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6464b60faea854743c4ef148_Reflections-photobook-Danilo-and-Sharon-28-p-500.avif" alt="" /></div>
                    </div>
                    <div className="text-box-right">
                        <div className="right animate-section"><img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6464b60d0fbc2c8086b24113_Reflections-photobook-Danilo-and-Sharon-43-p-500.avif" alt="" /></div>
                    </div>
                    <div className="text-box-center">
                        <div className="left animate-section">Được kể trên 200 trang giấy ảnh đạt chuẩn bảo tàng.</div>
                        <div className="right animate-section"><img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6464b60f64ccc9f863c1f535_Reflections-photobook-Danilo-and-Sharon-34-p-500.avif" alt="" /></div>
                    </div>

                </div>
                <div className="section-4-kld2812">
                    <div className="btn-text animate-section">NHẬN THÊM THÔNG TIN</div>
                </div>
                <div className="section-5-kld2812">
                    <div className="section-5-1">
                        <div className="col-md-6 left">
                            Câu chuyện bắt đầu trước khi mở cuốn sách .
                        </div>
                        <div className="col-md-6 right">
                            <img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6465c3e9820e9ee2db11921a_Reflections-photobook-Danilo-and-Sharon-30-2.avif" alt="" />
                        </div>
                    </div>
                    <div className="section-5-2">
                        <div className="col-md-6 left">
                            <img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6464b60d5b1411bb06e725f8_Reflections-photobook-Danilo-and-Sharon-47.avif" alt="" />
                        </div>
                        <div className="col-md-6 right">
                            Nhưng mọi chuyện vẫn chưa kết thúc khi bạn đóng nó lại.
                        </div>
                    </div>
                    <div className="section-5-3">
                        <div className="col-md-6 left">
                            Với mỗi thay đổi trong sự phản chiếu của bạn , câu chuyện sẽ phát triển.
                        </div>
                        <div className="col-md-6 right">
                            <img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6464b60e9be93d6e98184fa3_Reflections-photobook-Danilo-and-Sharon-45-p-800.avif" alt="" />
                        </div>
                    </div>
                </div>
                <div className="section-6-kld2812">
                    <div className="btn-text animate-section">Thông tin chi tiết</div>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                BẠN CÓ THỂ ĐẶT HÀNG SÁCH REFLECTIONS NGAY BÂY GIỜ
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="text">Chỉ cần điền vào mẫu đơn giản ở cuối trang này.</div>
                                    <div className="text">Hoặc gửi email cho chúng tôi theo địa chỉ dongduclan277@gmail.com</div>
                                    <div className="text">Chúng tôi sẽ phản hồi bạn với tất cả thông tin đặt hàng và giá cả sớm nhất có thể.</div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                CUỐN SÁCH CÓ 200 TRANG VÀ BỐ CỤC THIẾT KẾ RIÊNG, và nó rất lớn
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="text">Chúng tôi sẽ thiết kế bố cục tùy chỉnh cho bạn. Bạn có thể sử dụng toàn bộ 200 trang của cuốn sách để phù hợp với 180-190 bức ảnh.</div>
                                    <div className="text">Thiết kế bố cục của cuốn sách được thực hiện tại studio của chúng tôi và chúng tôi cho phép tối đa một lần đánh giá thiết kế trong giá cơ bản của cuốn sách.</div>
                                    <div className="text">Kích thước của cuốn sách là 25x32cm.</div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                Mỗi cuốn sách được trình bày trong một hộp kẹo đỏ
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="text">Hộp acrylic trong suốt "Red Candy" đi kèm với mỗi cuốn Reflection không chỉ bảo vệ và giữ gìn cuốn sách mà còn giúp bìa sách sáng bóng ngay cả khi bạn để sách ở xa.</div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                BẠN CÓ THỂ ĐẶT MUA SÁCH REFLECTIONS NGAY CẢ KHI CHÚNG TÔI KHÔNG PHẢI LÀ NHIẾP ẢNH GIA ĐÁM CƯỚI CỦA BẠN
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="text">Sách ảnh Reflections có thể mua bất kể nhiếp ảnh gia của bạn chọn gì. Chỉ cần gửi email cho chúng tôi những bức ảnh có độ phân giải đầy đủ và chúng tôi sẽ thiết kế bố cục tùy chỉnh.</div>
                                    <div className="text">Tất nhiên, chúng tôi sẽ đảm bảo ghi rõ nhiếp ảnh gia của bạn là tác giả của những bức ảnh.</div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                BẠN CÓ THỂ NHẬN MỘT SÁCH SUY NGHĨ LÀM QUÀ TẶNG ĐÁM CƯỚI
                                </button>
                            </h2>
                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="text">Vẻ đẹp vượt thời gian của một cuốn sách Reflection khiến nó trở thành món quà cưới hoàn hảo, tượng trưng cho sự khởi đầu của một hành trình tuyệt đẹp.</div>
                                    <div className="text">Hãy truyền đạt thông tin này đến khách mời của bạn và để chúng tôi lo phần còn lại.</div>
                                    <div className="text">Chúng tôi sẽ đảm bảo rằng bạn nhận được cuốn sách Reflection tuyệt đẹp như một món quà trân trọng cho ngày đặc biệt của mình, tạo ra một kỷ vật quý giá để sống lại những khoảnh khắc tuyệt đẹp của bạn trong suốt cuộc đời.</div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                Tận hưởng sự an tâm với chế độ bảo hành trọn đời bao gồm cả trường hợp rơi và đổ
                                </button>
                            </h2>
                            <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="text">Chúng tôi muốn đảm bảo rằng mọi chủ sở hữu của cuốn sách Reflections đều cảm thấy tự tin khi thưởng thức cuốn sách hàng ngày, thay vì cất nó đi.</div>
                                    <div className="text">Đó là lý do tại sao tất cả các cuốn sách của chúng tôi đều được bảo hành trọn đời, không chỉ đảm bảo chất lượng của cuốn sách mà còn bao gồm cả bảo hành chống rơi và đổ. Trẻ em, rượu vang, vật nuôi. Bạn cứ nói, chúng tôi sẽ đổi.</div>
                                    <div className="text">Mỗi cuốn sách đều được in và làm thủ công tại EU, bởi đối tác Fravero Prophoto của chúng tôi.</div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                SẼ MẤT 4-5 TUẦN SAU KHI THIẾT KẾ ĐƯỢC PHÊ DUYỆT ĐỂ SÁCH ĐƯỢC GIAO ĐẾN NHÀ BẠN (MIỄN PHÍ).
                                </button>
                            </h2>
                            <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <div className="text">Giao hàng DHL trên toàn thế giới miễn phí và mất 4-5 tuần kể từ khi thiết kế được chấp thuận để nhận được sách.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-7-kld2812">
                    <div className="section-7-1">
                        <div className="col-md-6 left">
                        Nhận thêm thông tin về sách ảnh REFLECTIONS NGAY BÂY GIỜ .
                        </div>
                        <div className="col-md-6 right">
                            <img src="https://cdn.prod.website-files.com/63170356296f85970dadc536/6464b60f68f2f33bbedbe8a9_Reflections-photobook-Danilo-and-Sharon-16.avif" alt="" />
                        </div>
                    </div>
                </div>
                <div className="section-11-kld2812">
                    <div className="title animate-section">Dear John & Diana</div>
                    <div className="box-sent">
                        <div className=' animate-section text'>Tên tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Họ & Tên' />
                        <div className="text animate-section">và tôi đang lên kế hoạch</div>
                        <input type="text" className=' animate-section input-text' placeholder='Lễ cưới' />
                        <div className="text animate-section">trong</div>
                        <input type="text" className=' animate-section input-text' placeholder='Trung tâm' />
                        <div className="text animate-section">vào</div>
                        <input type="text" className=' animate-section input-text' placeholder='Ngày x tháng x năm xxxx' />
                        <div className="text animate-section">người lập kế hoạch đám cưới của tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='Nguyễn Văn A' />
                        <div className="text animate-section">và tôi là</div>
                        <input type="text" className=' animate-section input-text' placeholder='@Nguyễn Thị B' />
                        <div className="text animate-section">trên instagram</div>
                    </div>
                    <div className="btn-sent animate-section">Vui lòng chờ...</div>
                </div>
            </div>
            <FooterKLD2812 />
        </>
    );
}

export default Index;
