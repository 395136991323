import React, { useState, useEffect, useRef } from 'react';
import 'assets/Homepage/scss/Landing_page.scss';
import { NavLink, useLocation } from 'react-router-dom';
import Loading from 'components/Homepage/About/Loading';
import HeaderHomepage from 'components/Homepage/Header';
import FooterHomepage from 'components/Homepage/Footer';
import { Helmet } from 'react-helmet';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import {
    updateViewTemplate
} from 'Apis'
import { useApp } from 'components/Homepage/context/AppContext';
const Index = () => {
    const { dataIp, fetchPostIP, productList, setProductList } = useApp();
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const path = location.pathname
    const pageCategory = path.split("/").pop()
    const [product, setProduct] = useState([
        {
            id: 'KLD2112',
            img: 'https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/bds_m0dtnd.png',
            src1: 'https://kassdev.com/website/template/KLD2112/',
            src: '/template/KLD2112',
            title: 'Bất động sản DC',
            name: 'mẫu bất động sản DC - KLD2112',
            view: 0,
            time: '4 - 7',
            price: 'Giá liên hệ',
            type: 'Cá nhân & Tổ chức',
            category: 'website-bat-dong-san'
        }
    ]);

    useEffect(() => {
        if (dataIp) {
            fetchPostIP({...dataIp, urlVisit:'/category/website-bat-dong-san'})
            .then(() => {
                console.log('sent')
            })
        }
        const foundProducts = productList.filter(product => product.category === pageCategory);
        setProduct(foundProducts);
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, [dataIp]);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-top-1s",
                ".animate-kassdev-right-1s",
                ".animate-kassdev-left-1s",
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-top-2s",
                ".animate-kassdev-right-2s",
                ".animate-kassdev-left-2s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-top-3s",
                ".animate-kassdev-right-3s",
                ".animate-kassdev-left-3s",
                ".animate-kassdev-bottom-3s",
                ".animate-kassdev-top-4s",
                ".animate-kassdev-right-4s",
                ".animate-kassdev-left-4s",
                ".animate-kassdev-bottom-4s",
                ".animate-kassdev-top-5s",
                ".animate-kassdev-right-5s",
                ".animate-kassdev-left-5s",
                ".animate-kassdev-bottom-5s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);

    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);
    const handleViewTemplate = (id) => {
        updateViewTemplate(id)
            .then((result) => {
                // console.log(result)
                const updatedProductList = productList.map((product) =>
                    product.id === id
                        ? { ...product, ...result }
                        : product
                );
                setProductList(updatedProductList);
            }).catch((err) => {
                console.log(err)
            });
    }
    return (
        <>
            {isLoading ?
                <Loading />
                :
                <><Helmet>
                <title>Mẫu website event - Mẫu website sự kiện</title>
                <meta name="description" content="Mẫu website giới thiệu Event thường được sử dụng để quảng bá cho các chương trình, sự kiện quan trọng của các công ty, doanh nghiệp. Thông thường, các sự kiện này có thể diễn ra online hoặc offline, nhưng để thu hút sự chú ý và quan tâm của các khách hàng tiềm năng từ trước khi sự kiện diễn ra, người ta thường thiết kế các trang web dạng Landing Page hoặc Website tổng hợp. Với các website này người dùng không chỉ có thể vào cập nhật các thông tin cơ bản về sự kiện sắp diễn ra mà còn có thể thực hiện hành động (Call to Action) như điền form đăng kí tham gia, mua vé tham dự, đặt chỗ trước…" />
                <meta name="keywords" content="mẫu website event, thiết kế website sự kiện, website giới thiệu event, mẫu website quảng bá sự kiện, website tổ chức sự kiện, landing page sự kiện, website đăng ký sự kiện, website mua vé sự kiện, thiết kế website sự kiện online, website sự kiện offline, website tổ chức sự kiện chuyên nghiệp, website sự kiện đẹp, website sự kiện hiệu quả, mẫu website landing page sự kiện, website event chuẩn SEO, website sự kiện responsive, website event cho doanh nghiệp, website event cho công ty, thiết kế website event độc quyền"/>
            </Helmet>
                    <HeaderHomepage />
                    <div className='category-landing-page'>
                        <div className='row banner-image'>
                            <div className='col-md-7 image animate-kassdev-left-2s'>
                            <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733751651/education_pwiqj4.png' alt='' />
                            </div>
                            <div className='col-md-5 content' style={{ alignItems: 'center' }} >
                                <h6 className='text'>
                                    <div  className=' animate-kassdev-right-1s'style={{ textAlign: 'center' }}>Website Bất động sản</div>
                                    <div  className=' animate-kassdev-right-1s'style={{ textAlign: 'end' }}></div>
                                </h6>
                                <div style={{ padding: '20px 0' }} className=' animate-kassdev-right-3s'><a href='tel:0379382992' className='tel' >Tư vấn thiết kế</a></div>
                            </div>
                        </div>
                        <div className='part-product'>
                            <div className='title animate-kassdev-right-2s'>Những sản phẩm bạn có thể <span style={{color:'#50adff'}}>hứng thú</span></div>
                            <div className='row list-product'>
                                {
                                    product.map((item, index) => (
                                        <div onClick={() => handleViewTemplate(item.id)} className={index % 2 === 0 ? 'col-md-6 animate-kassdev-left-1s' : 'col-md-6 animate-kassdev-right-1s'}>
                                            <NavLink key={index} to={item.src} className='nav-link'>
                                                <div className="image-container">
                                                    <img
                                                        src={item.img}
                                                        alt={`Image ${index}`}
                                                    />
                                                </div>
                                                <p className='product-name'>{item.name}</p>

                                                <div className='product-price '>{item.price} <span style={{ fontSize: '13px', textTransform: 'uppercase', color: 'white' }}> - {item.view} lượt xem</span></div>
                                                <div className='btn-show'>
                                                    <div className="btn-type">Xem chi tiết</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='row introduce-landing-page'>
                            <div className='col-md-6 text'>
                                <h6 className='title animate-kassdev-left-1s'>Mẫu website bất động sản</h6>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'><span style={{ color: '#50adff' }}>Mẫu website giới thiệu bất động sản</span> thường được sử dụng để quảng bá cho các chương trình, Bất động sản quan trọng của các công ty, doanh nghiệp. Thông thường, các Bất động sản này có thể diễn ra online hoặc offline, nhưng để thu hút sự chú ý và quan tâm của các khách hàng tiềm năng từ trước khi Bất động sản diễn ra, người ta thường thiết kế các trang web dạng Landing Page hoặc Website tổng hợp. Với các website này người dùng không chỉ có thể vào cập nhật các thông tin cơ bản về Bất động sản sắp diễn ra mà còn có thể thực hiện hành động (Call to Action) như điền form đăng kí tham gia, mua vé tham dự, đặt chỗ trước…</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Chính vì vậy việc <span style={{ color: '#50adff' }}>thiết kế một website giới thiệu Event, Bất động sản</span> trở nên cần thiết đối với những ai đã và đang hoạt động tỏng lĩnh vực even, Bất động sản. <span style={{ color: '#50adff' }}>DUC LAN WEBSITE</span>, một trong những đơn vị chuyên sưu tầm và bày bán các giao diện website đẹp trên Internet, đã tổng hợp các <span style={{ color: '#50adff' }}>mẫu website giới thiệu Event, Bất động sản</span> đẹp và hiệu quả nhất</p>
                            </div>
                            <div className='col-md-6 image-intro animate-kassdev-right-2s'>
                            <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733756969/11111_rcy8u6.png' alt='' />
                            </div>
                        </div>
                        <div className='row type-website'>
                            <div className='title animate-kassdev-bottom-1s'>Danh Mục</div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Landing Page</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Studio - Wedding</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-3s'>
                                        <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Bất động sản</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>

                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Thương Mại Điện Tử Nhỏ</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Dashboard</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Giáo Dục</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Nhà Hàng - Quán Cà Phê</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FooterHomepage />
                </>
            }
        </>
    );
};

export default Index;
