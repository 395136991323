import React, { useState, useEffect, useRef } from 'react';
import 'assets/Homepage/scss/Landing_page.scss';
import { NavLink, useLocation } from 'react-router-dom';
import Loading from 'components/Homepage/About/Loading';
import HeaderHomepage from 'components/Homepage/Header';
import FooterHomepage from 'components/Homepage/Footer';
import { Helmet } from 'react-helmet';
import 'lenis/dist/lenis.css'
import Lenis from 'lenis'
import {
    updateViewTemplate
} from 'Apis'
import { useApp } from 'components/Homepage/context/AppContext';
const Index = () => {
    const { dataIp, fetchPostIP, productList, setProductList } = useApp();
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const path = location.pathname
    const pageCategory = path.split("/").pop()
    const [product, setProduct] = useState([
        {
            id: 'KLD0003',
            img: 'https://res.cloudinary.com/dolydpat4/image/upload/v1735832434/xg_ae1cu0.png',
            src1: 'https://www.xeghepbacgiangbacninhhanoi.net',
            src: '/template/KLD0003',
            name: 'mẫu landing page xe ghép - KLD0003',
            title: 'Xe ghép đức anh',
            view: 0,
            time: '4 - 7',
            price: 'Giá liên hệ',
            type: 'Cá nhân & Tổ chức',
            category: 'website-landing-page'
        }
    ]);
    useEffect(() => {
        if (dataIp) {
            fetchPostIP({...dataIp, urlVisit:'/category/landing-page'})
            .then(() => {
                console.log('sent')
            })
        }
        const foundProducts = productList.filter(product => product.category === pageCategory);
        setProduct(foundProducts);
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
    }, [dataIp]);
    useEffect(() => {
        if (!isLoading) {
            const sectionSelectors = [
                ".animate-kassdev-top-1s",
                ".animate-kassdev-right-1s",
                ".animate-kassdev-left-1s",
                ".animate-kassdev-bottom-1s",
                ".animate-kassdev-top-2s",
                ".animate-kassdev-right-2s",
                ".animate-kassdev-left-2s",
                ".animate-kassdev-bottom-2s",
                ".animate-kassdev-top-3s",
                ".animate-kassdev-right-3s",
                ".animate-kassdev-left-3s",
                ".animate-kassdev-bottom-3s",
                ".animate-kassdev-top-4s",
                ".animate-kassdev-right-4s",
                ".animate-kassdev-left-4s",
                ".animate-kassdev-bottom-4s",
                ".animate-kassdev-top-5s",
                ".animate-kassdev-right-5s",
                ".animate-kassdev-left-5s",
                ".animate-kassdev-bottom-5s",
            ];
            const sections = sectionSelectors.flatMap((selector) =>
                Array.from(document.querySelectorAll(selector))
            );

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("animate-kassdev-show");
                        }
                    });
                },
                { threshold: 0.1 }
            );

            sections.forEach((section) => observer.observe(section));

            // Cleanup observer
            return () => sections.forEach((section) => observer.unobserve(section));
        }
    }, [isLoading]);

    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        return () => lenis.destroy();
    }, []);

    const handleViewTemplate = (id) => {
        updateViewTemplate(id)
            .then((result) => {
                // console.log(result)
                const updatedProductList = productList.map((product) =>
                    product.id === id
                        ? { ...product, ...result }
                        : product
                );
                setProductList(updatedProductList);
            }).catch((err) => {
                console.log(err)
            });
    }

    return (
        <>
            {isLoading ?
                <Loading />
                :
                <><Helmet>
                    <title>Mẫu Website Landing Page</title>
                    <meta name="description" content="Mẫu website landing page là một loại website đang rất được ưa chuộng hiện nay vì hiệu quả mà nó mang lại. Giao diện landing page sẽ phù hợp nhất cho những website giới thiệu công ty tổng quan và chuyên sâu về công ty hoặc một sản phẩm nào đó nhằm PR, quảng cáo sản phẩm, công ty đến với khách hàng một cách nhanh và thân thiện nhất. Bản chất của Landing page sẽ thể hiện tất cả thông tin về công ty hoặc sản phẩm lên trên cùng một trang web, khách hàng có thể theo dõi tất cả thông tin mà không cần chuyển đi bất cứ một trang nào khác." />
                    <meta name="keywords" content="mẫu website Landing Page, Landing Page đẹp, thiết kế Landing Page, mẫu Landing Page chuẩn SEO, landing page công ty, landing page sản phẩm, landing page bất động sản, landing page mỹ phẩm, landing page xe hơi, landing page GYM, thiết kế website landing page, tạo landing page, thiết kế website chuẩn SEO, website một trang, tối ưu landing page, landing page chuẩn responsive, dịch vụ thiết kế landing page, xây dựng landing page theo yêu cầu, dịch vụ SEO chuyên nghiệp, landing page tại DUC LAN, thiết kế website tại DUC LAN, dịch vụ website DUC LAN" />
                </Helmet>
                    <HeaderHomepage />
                    <div className='category-landing-page'>
                        <div className='row banner-image'>
                            <div className='col-md-7 image animate-kassdev-left-2s'>
                                <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733751652/landingpage_xu4ais.png' alt='' />
                            </div>
                            <div className='col-md-5 content'>
                                <h6 className='text'>
                                    <div className='animate-kassdev-right-1s'>landing</div>
                                    <div className='animate-kassdev-right-2s'>page</div>
                                </h6>
                                <div style={{ padding: '20px 0' }} className=' animate-kassdev-right-3s'><a href='tel:0379382992' className='tel' >Tư vấn thiết kế</a></div>
                            </div>
                        </div>
                        <div className='part-product'>
                            <div className='title animate-kassdev-right-2s'>Những sản phẩm bạn có thể <span style={{ color: '#50adff' }}>hứng thú</span></div>
                            <div className='row list-product'>
                                {
                                    product.map((item, index) => (
                                        <div onClick={() => handleViewTemplate(item.id)} className={index % 2 === 0 ? 'col-md-6 animate-kassdev-left-1s' : 'col-md-6 animate-kassdev-right-1s'}>
                                            <NavLink key={index} to={item.src} className='nav-link'>
                                                <div className="image-container">
                                                    <img
                                                        src={item.img}
                                                        alt={`Image ${index}`}
                                                    />
                                                </div>
                                                <p className='product-name'>{item.name}</p>

                                                <div className='product-price '>{item.price} <span style={{ fontSize: '13px', textTransform: 'uppercase', color: 'white' }}> - {item.view} lượt xem</span></div>
                                                <div className='btn-show'>
                                                    <div className="btn-type">Xem chi tiết</div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='row introduce-landing-page'>
                            <div className='col-md-6 text'>
                                <h6 className='title animate-kassdev-left-1s'>Mẫu website Landing Page</h6>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Mẫu website landing page là một loại website đang rất được ưa chuộng hiện nay vì hiệu quả mà nó mang lại. <span style={{ color: '#50adff' }}>Giao diện landing page</span> sẽ phù hợp nhất cho những <span style={{ color: '#50adff' }}>website giới thiệu công ty</span> tổng quan và chuyên sâu về công ty hoặc một sản phẩm nào đó nhằm PR, quảng cáo sản phẩm, công ty đến với khách hàng một cách nhanh và thân thiện nhất. Bản chất của Landing page sẽ thể hiện tất cả thông tin về công ty hoặc sản phẩm lên trên cùng một trang web, khách hàng có thể theo dõi tất cả thông tin mà không cần chuyển đi bất cứ một trang nào khác.</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'><span style={{ color: 'grey' }}>Mẫu Landing page đẹp</span> có thể sử dụng tối ưu cho tất cả các loại hình kinh doanh đặc biệt là: landing page BĐS, Landing page Mỹ phẩm, Landing page công ty xe hơi, Landing page GYM. Ngoài ra tuy chỉ trên một trang web duy nhất nhưng website tại DUC LAN WEBSITE vẫn được tối ưu hóa bằng thiết kế <span style={{ color: '#50adff' }}>chuẩn SEO, chuẩn Responsive- chuẩn di động</span>. Khách hàng khi hợp tác cùng với Mona Media hãy tin tưởng rằng chúng tôi có một đội ngũ nhân viên chuyên nghiệp, chế độ hậu đãi cực tốt và dịch vụ SEO chuyên nghiệp có thể giúp cho website của bạn đạt những vị trí cao trên các cổ máy tìm kiếm.</p>
                                <br></br>
                                <p className='content animate-kassdev-left-1s'>Khả năng chính của chúng tôi là xây dựng landing page theo yêu cầu. Vì vậy nếu bạn cần xây dựng riêng thì đừng ngần ngại liên hệ hoặc để lại thông tin – chuyên viên của chúng tôi sẽ hỗ trợ tư vấn ngay với bạn</p>
                            </div>
                            <div className='col-md-6 image-intro animate-kassdev-right-2s'>
                                <img src='https://res.cloudinary.com/dolydpat4/image/upload/v1733756969/11111_rcy8u6.png' alt='' />
                            </div>
                        </div>
                        <div className='row type-website'>
                            <div className='title animate-kassdev-bottom-1s'>Danh Mục</div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/landing-page' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Landing Page</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/studio-chup-anh-cuoi' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Studio - Wedding</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-3s'>
                                        <NavLink to='/category/website-bat-dong-san' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Bất động sản</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>

                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-thuong-mai-dien-tu' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Thương Mại Điện Tử Nhỏ</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-dashboard' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Dashboard</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-4 content'>
                                <ul>
                                    <li className='animate-kassdev-bottom-1s'>
                                        <NavLink to='/category/website-giao-duc' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Giáo Dục</p>
                                        </NavLink>
                                    </li>
                                    <li className='animate-kassdev-bottom-2s'>
                                        <NavLink to='/category/website-nha-hang-quan-ca-phe' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p>Website Nhà Hàng - Quán Cà Phê</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <FooterHomepage />
                </>
            }
        </>
    );
};

export default Index;
